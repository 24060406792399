import api from '@/libs/axios'
import { PaginatedList } from '../../models/common/paginatedList'
import { CreateTacheModel, Tache } from '@/api/models/suiviTaches/tache';

const baseResource = 'Entreprises'
const resource = 'Taches'

export default class TachesEntrepriseResource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number): Promise<PaginatedList<Tache>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params })
    return response.data
  }

  public async getById(entrepriseId: string, id: string) {    
    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async post(entrepriseId: number, data : CreateTacheModel, config: any) : Promise<Tache> {
    let formData = new FormData();

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    if (data.dateRealisation) formData.append('dateRealisation', data.dateRealisation)
    if (data.entrepriseId) formData.append('entrepriseId', data.entrepriseId.toString())
    if (data.typeTacheId) formData.append('typeTacheId', data.typeTacheId.toString())
    if (data.dateConsigneEcheance) formData.append('dateConsigneEcheance', data.dateConsigneEcheance.toString())
    if (data.consigneEcheanceActivee) formData.append('consigneEcheanceActivee', data.consigneEcheanceActivee.toString())

    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(entrepriseId: string, id: string) : Promise<void> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(entrepriseId: string, id: string, fileId: string) : Promise<void> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }

  public async put(entrepriseId: string, id : string, data : CreateTacheModel, config: any) : Promise<Tache> {
    let formData = new FormData();

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    if (data.dateRealisation) formData.append('dateRealisation', data.dateRealisation)
    if (data.entrepriseId) formData.append('entrepriseId', data.entrepriseId.toString())
    if (data.id) formData.append('id', data.id.toString())
    if (data.typeTacheId) formData.append('typeTacheId', data.typeTacheId.toString())
    if (data.dateConsigneEcheance) formData.append('dateConsigneEcheance', data.dateConsigneEcheance.toString())
    if (data.consigneEcheanceActivee) formData.append('consigneEcheanceActivee', data.consigneEcheanceActivee.toString())

    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, formData, config)
    return response.data
  }
}
