import { CreateTenantPreferenceModel, TenantPreference } from "../models/tenantPreferences/tenantPreference"
import api from '@/libs/axios'

const resource = 'tenantPreferences'

export default class TenantPreferencesResource {
    public async get() : Promise<TenantPreference> {
      const response = await api.get(`${resource}`)
      return response.data
    }
  
    public async getById(id : string) : Promise<TenantPreference> {
      const response = await api.get(`${resource}/${id}`)
      return response.data
    }
  
    public async delete(id: string) : Promise<TenantPreference> {
      const response = await api.delete(`${resource}/${id}`)
      return response.data
    }
  
    public async post( data : CreateTenantPreferenceModel) : Promise<TenantPreference> {
      const response = await api.post(`${resource}`, data)
      return response.data
    }
  
    public async put(data : TenantPreference) : Promise<TenantPreference> {
      const response = await api.put(`${resource}/${data.id}`, data)
      return response.data
    }
  }
  