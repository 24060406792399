import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Tenant } from '../models/options/tenants/tenant';

const resource = 'Tenants'

export default class TenantsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Tenant>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
