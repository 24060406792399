import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { DemandeSurendettement } from '../models/options/demandesSurendettement/demandeSurendettement'

const baseResource = 'dossier'
const resource = 'DemandesSurendettement'

export default class DemandeSurendettementResource {
    public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<DemandeSurendettement>> {

        const params = new URLSearchParams();
    
        if (pagenumber) params.append('PageNumber', pagenumber.toString());
    
        if (pagesize) params.append('PageSize', pagesize.toString());
    
        if (search && search.length) params.append('Search', search?.toString());
        
    
        const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params } )
    
        return response.data
    }

  public async post(dossierId : string, demande: DemandeSurendettement, config : any) : Promise<DemandeSurendettement> {
    let formData = new FormData();

    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateProposition) formData.append('dateProposition', demande.dateProposition)
    if (demande.dateRecevabilite) formData.append('dateRecevabilite', demande.dateRecevabilite)
    if (demande.dateNouveauDepot) formData.append('dateNouveauDepot', demande.dateNouveauDepot)
    formData.append('recevable', demande.recevable.toString())
    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(dossierId : string, id : string) : Promise<DemandeSurendettement> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async put(dossierId : string, id : string, demande : DemandeSurendettement, config : any ) : Promise<DemandeSurendettement> {
    let formData = new FormData();

    formData.append('id', id)
    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateProposition) formData.append('dateProposition', demande.dateProposition)
    if (demande.dateRecevabilite) formData.append('dateRecevabilite', demande.dateRecevabilite)
    if (demande.dateNouveauDepot) formData.append('dateNouveauDepot', demande.dateNouveauDepot)
    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    formData.append('recevable', demande.recevable.toString())
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, formData)
    return response.data
  }

  public async getById(dossierId : string, id : string) : Promise<DemandeSurendettement> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, fileId: string) : Promise<DemandeSurendettement> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}