export default [
  {
    path: '/admin/entreprises',
    name: 'admin-entreprises',
    component: () => import('@/views/administration/entreprises/Search.vue'),
    meta: {
      pageTitle: 'Gérer les entreprises',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les entreprises',
          active: true,
        },
      ],
      rights: ['ADMINISTRATION_ENTREPRISE']
    },
  },
  {
    path: '/admin/entreprise/details/:id',
    name: 'admin-entreprise-details',
    component: () => import('@/views/administration/entreprises/Details.vue'),
    meta: {
      pageTitle: 'Gérer les details de l\'entreprise',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les entreprises',
        },
        {
          text: 'Details',
          active: true,
        },
      ],
      rights: ['ADMINISTRATION_ENTREPRISE']
    },
  },
  {
    path: '/admin/profils',
    name: 'admin-profils',
    component: () => import('@/views/administration/utilisateurs/Index.vue'),
    meta: {
      pageTitle: 'Gestion des utilisateurs',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les utilisateurs',
        },
      ],
      rights: [
        'ADMINISTRATION',
      ],
    },
  },
  {
    path: '/admin/profils/:userDetailsId',
    name: 'admin-profils-user-details',
    component: () => import('@/views/administration/utilisateurs/Profils/Index.vue'),
    meta: {
      pageTitle: 'Gestion des utilisateurs',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les utilisateurs',
        },
      ],        
      rights: [
        'ADMINISTRATION',
      ],
    },
  },
  {
    path: '/admin/equipes',
    name: 'admin-equipes',
    component: () => import('@/views/administration/equipes/List.vue'),
    meta: {
      pageTitle: 'Gérer les équipes consignes',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les équipes consignes',
          active: true,
        },
      ],        
      rights: [
        'EQUIPES'
      ],
    },
  },
  {
    path: '/admin/equipes/:equipeId',
    name: 'admin-equipes-details',
    component: () => import('@/views/administration/equipes/Details.vue'),
    meta: {
      pageTitle: 'Gérer les membres de l\'équipe consignes',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les équipes consignes',
          active: true,
        },
        {
          text: 'Gérer les membres de l\'équipe consignes',
          active: true,
        },
      ],        
      rights: [
        'EQUIPES'
      ],
    },
  },
  {
    path: '/admin/rapport-activite',
    name: 'admin-rapport-activite',
    component: () => import('@/views/administration/rapports-activites-templates/List.vue'),
    meta: {
      pageTitle: 'Gérer les templates de rapport d\'activité',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les templates de rapport d\'activité',
          active: true,
        },
      ],   
    },
  },
  {
    path: '/admin/filtres-ra',
    name: 'admin-filtres-ra',
    component: () => import('@/views/administration/filtres-ra/ListFiltresRA.vue'),
    meta: {
      pageTitle: 'Gérer les filtres de rapports d\'activité',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les filtres de rapports rapport d\'activité',
          active: true,
        },
      ],   
    },
  },
  {
    path: '/admin/filtres-ra/:filtreId',
    name: 'admin-filtre-ra-details',
    component: () => import('@/views/administration/filtres-ra/DetailsFiltre.vue'),
    meta: {
      pageTitle: 'Gérer le filtre de rapport d\'activité',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer le filtre de rapport d\'activité',
          active: true,
        },
      ],   
    },
  },
  {
    path: '/admin/rapport-activite/:rapportActiviteTemplateId',
    name: 'admin-rapport-activite-details',
    component: () => import('@/views/administration/rapports-activites-templates/Details.vue'),
    meta: {
      pageTitle: 'Gérer les graphiques du template de rapport d\'activité',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les graphiques du template de rapport d\'activité',
          active: true,
        },
      ],   
    },
  },
]
