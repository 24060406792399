import { WebStorageStateStore } from 'oidc-client'
import AuthService from './authService'
import appSettings from '@/appSettings'
import { CookieStorage } from 'cookie-storage';
const STS_DOMAIN = appSettings.getIdentityURL()
const CURRENT_HOST_FRONT = appSettings.getFrontURL()

var userStorage: CookieStorage;
var stateStorage: CookieStorage;

const APP_ENVS = ['ressif-dev', 'ressif-apps', 'ressif-staging', 'localhost'];
const hostname = new URL(CURRENT_HOST_FRONT).hostname;


var userStorage: CookieStorage = new CookieStorage({
    path: '/',
    secure: true,
    sameSite: 'None',
});
var stateStorage: CookieStorage = new CookieStorage({
    path: '/',
    secure: true,
    sameSite: 'None',
});


switch (APP_ENVS.find(sd => hostname.includes(sd))) {
    case 'localhost':
        console.log('localhost')
        userStorage = new CookieStorage({
            path: '/',
            secure: true,
            sameSite: 'None',
        });
        stateStorage = new CookieStorage({
            path: '/',
            secure: true,
            sameSite: 'None',
        });
        break;
    
    case 'ressif-dev':
        console.log('dev')
        userStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-dev.com"
        });
        stateStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-dev.com"
        });
        break;

        
    case 'ressif-staging':
        console.log('stag')
        userStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-staging.com"
        });
        stateStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-staging.com"
        });
        break;

    case 'ressif-apps':
        console.log('stag')
        userStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-apps.com"
        });
        stateStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-apps.com"
        });
        break;

    default:
        console.log('stag')
        userStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-apps.com"
        });
        stateStorage = new CookieStorage({
            path: '/',
            //@ts-ignore
            httpOnly: true,
            secure: true,
            sameSite: 'Strict',
            domain: ".ressif-apps.com"
        });
        break;
};

const settings = {
    // userStore: new WebStorageStateStore({ store: window.localStorage }),
    userStore: new WebStorageStateStore({ store: userStorage }),
    stateStore: new WebStorageStateStore({ store: stateStorage }),
    authority: STS_DOMAIN,
    client_id: appSettings.getClientName(),
    redirect_uri: `${appSettings.getFrontURL()}callback`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${appSettings.getFrontURL()}silent-renew`,
    response_type: 'code',
    scope: 'openid profile',
    post_logout_redirect_uri: appSettings.getFrontURL(),
    filterProtocolClaims: true,
    clockSkew: 7200000
};
const authService = new AuthService(settings)
export default authService