export default [
  {
    path: '/search-suivi-entreprise',
    name: 'search-suivi-entreprise',
    component: () => import('@/views/suivi-entreprise/Search.vue'),
    meta: {
      pageTitle: 'Suivi entreprise',
      breadcrumb: [
        {
          text: 'Suivi entreprise',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "ENTREPRISE_MANAG_TOTAL",
        "ENTREPRISE",
      ]
    },
  },
  {
    path: '/suivi-entreprise/details/:idEntreprise',
    name: 'details-suivi-entreprise',
    component: () => import('@/views/suivi-entreprise/_NavigationTabs.vue'),
    meta: {
      pageTitle: 'Suivi entreprise',
      breadcrumb: [
        {
          text: 'Suivi entreprise',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "ENTREPRISE_MANAG_TOTAL",
        "ENTREPRISE",
      ]
    },
  }
]
  