import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Equipe } from '../models/options/equipes/equipe';

const resource = 'Equipes'

export default class EquipesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Equipe>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Equipe> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: Equipe) : Promise<Equipe> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Equipe> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async addMembre(equipeId: string, membreId: string) : Promise<Equipe> {
    var data = {
      equipeId: equipeId,
      membreId: membreId
    }

    const response = await api.post(`${resource}/${equipeId}/Membres`, data)
    return response.data
  }

  public async removeMembre(equipeId: string, membreId: string) : Promise<Equipe> {
    const response = await api.delete(`${resource}/${equipeId}/Membres/${membreId}`)
    return response.data
  }
}
