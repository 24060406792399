import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import others from './routes/others'
import administration from './routes/administration'
import accompagnementIndividuel from './routes/accompagnement-individuel'
import preferencesUtilisateur from './routes/preferences-utilisateur'
import statistiques from './routes/statistiques'
import formsTable from './routes/forms-tables'
import consignes from './routes/consignes'
import authService from '@/auth/auth.service'
import suiviEntreprise from './routes/suivi-entreprise'
import rapportActivite from './routes/rapport-activites'
import charteGraphique from './routes/charte-graphique';
import documentsTelecharger from './routes/documents-telecharger';
import changelogs from './routes/changelogs'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'presentation' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...uiElements,
    ...others,
    ...administration,
    ...accompagnementIndividuel,
    ...documentsTelecharger,
    ...suiviEntreprise,
    ...statistiques,
    ...rapportActivite,
    ...charteGraphique,
    ...formsTable,
    ...consignes,
    ...preferencesUtilisateur,
    ...changelogs,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const user = await authService.getUser()
  const tokenExpired = await authService.willTokenExpire()
  const isLoggedIn = user && user.access_token && !tokenExpired
  if (
    !isLoggedIn
    && to.name !== 'misc-not-authorized'
    && to.name !== 'callback'
    && to.name !== 'silent-renew'
    && to.name !== 'presentation'
    && to.name !== 'user-confirm-rgpd'
    && to.name !== 'charts-apex-chart'
    && to.name !== 'charts-echart'
    && to.name !== 'charts-chartjs'
    && to.name !== 'extensions-drag-and-drop'
    && to.name !== 'ui-feather'
  ) {
    authService.tryLogin()
  } else if (!isLoggedIn) {
    return next()
  } else {
    // const userData = getUserData()

    if (to.name === 'presentation') {
      next({ name: 'home'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next({ name: 'home'})
    }

    if(canNavigate(to)){
      return next()
    }

    return next({ name: 'misc-not-authorized' })
  }

  return null
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
