import api from '@/libs/axios'
import { CreateRapportActiviteTemplateModel, RapportActiviteConfig, RapportActiviteTemplate, UpdateRapportActiviteConfigModel, UpdateRapportActiviteTemplateModel } from '../models/rapportActivites/rapportActiviteTemplate'
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'RapportActiviteTemplates'

export default class RapportActiviteTemplatesRessource {

  public async paginatedList(pagenumber?: number, pagesize?: number, ) : Promise<PaginatedList<RapportActiviteTemplate>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<RapportActiviteTemplate> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: CreateRapportActiviteTemplateModel) : Promise<any> {
    var formData = new FormData()
    
    if (data.charteGraphiqueId) formData.append('charteGraphiqueId', data.charteGraphiqueId)
    if (data.libelle) formData.append('libelle', data.libelle)
    if (data.fichierToUpload) formData.append('fichierToUpload', data.fichierToUpload)

    const response = await api.post(`${resource}`, formData)
    return response.data
  }

  public async put(templateId: string, data: UpdateRapportActiviteTemplateModel) {
    var formData = new FormData()
    
    if (data.charteGraphiqueId) formData.append('charteGraphiqueId', data.charteGraphiqueId)
    if (data.id) formData.append('id', data.id)
    if (data.libelle) formData.append('libelle', data.libelle)
    if (data.fichierToUpload) formData.append('fichierToUpload', data.fichierToUpload)

    const response = await api.put(`${resource}/${templateId}`, formData)
    return response.data
  }

  public async delete(templateId: string) : Promise<RapportActiviteTemplate> {
    const response = await api.delete(`${resource}/${templateId}`)
    return response.data
  }

  public async postConfig(templateId: string, data: UpdateRapportActiviteConfigModel) {
    const response = await api.post(`${resource}/${templateId}/config`, data)
    return response.data
  }

  public async updateConfig(templateId: string, configId: string, data: RapportActiviteConfig) {
    const response = await api.put(`${resource}/${templateId}/${configId}`, data)
    return response.data
  }

  public async removeConfig(templateId: string, configId: string) {
    const response = await api.delete(`${resource}/${templateId}/configs/${configId}`)
    return response.data
  }
}
