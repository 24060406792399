import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import authService from '@/auth/auth.service'

export default function useTenantPreferences() {

    const listTenantPreferences = computed({
        get: () => store.state.user.tenant_preferences
      })
      
      const fetchTenantPreferences = async () => {
        
        const accessToken = await authService.getAccessToken()
        const tokenExpired = await authService.willTokenExpire()
    
        if (accessToken && !tokenExpired) {
          await store.dispatch('user/fetchTenantPreferences')
        }
      }
      
      return {
        listTenantPreferences, 
        fetchTenantPreferences,    
      }
}