import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { Action, ActionAlone } from '../models/options/actions/action';
import { Intervention } from '../models/options/actions/intervention';
import { SousDomaine } from '../models/options/domaines/sousDomaine';
import { ActionCreateModelForm, InterventionCreateModelForm } from '../models/options/typeInterventions/typeIntervention'

const baseResource = 'dossiers'
const resource = 'Actions'

export default class ActionsResources {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number, orderby?: string, orderbydescending?: boolean): Promise<PaginatedList<Action>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (orderby) params.append('OrderBy', orderby.toString());
    if (orderbydescending) params.append('OrderByDescending', orderbydescending.toString());

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params });
    return response.data;
  }

  public async getById(dossierId: string, id: string) {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async post(dossierId: string, data : ActionCreateModelForm, config: any) : Promise<ActionCreateModelForm> {
    var formData = new FormData()
    
    if (data.dateAction) formData.append('dateAction', data.dateAction)
    if (data.situationProfessionnelleId) formData.append('contratId', data.situationProfessionnelleId)
    if (data.situationTravailId) formData.append('situationTravailId', data.situationTravailId)

    formData.append('isMAD', data.isMAD ? data.isMAD.toString() : false.toString())
    formData.append('nouvelleDemande', data.nouvelleDemande ? data.nouvelleDemande.toString() : false.toString())
    if (data.typeMiseADispositionId) formData.append('typeMiseADispositionId', data.typeMiseADispositionId)

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    data.interventions?.forEach((intervention: InterventionCreateModelForm, index) => {
      this.appendArray(formData, intervention, `interventions[${index}]`)
    })
    if (data.commentaire) formData.append('commentaire', data.commentaire)

    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(dossierId: string, id: string, contratId: string) : Promise<Action> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}?contratId=${contratId}`)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, contratId: string, fileId: string) : Promise<Action> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}?contratId=${contratId}`)
    return response.data
  }

  public async put(dossierId: string, id: string, contratId: string, data: ActionAlone): Promise<string> {
    let formData = new FormData();
    formData.append('id', id)

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    data.interventions?.forEach((intervention: Intervention, index) => {
      this.appendArray(formData, intervention, `interventions[${index}]`)
    })
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.dateAction) formData.append('dateAction', data.dateAction)
    if (data.dateAction) formData.append('situationTravailId', data.situationTravailId)
    formData.append('nouvelleDemande', data.nouvelleDemande.toString())

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}?contratId=${contratId}`, formData)
    return response.data
  }

  
  appendArray(form_data: any, values: any, name: any){
    if (!values && name)
      form_data.append(name, '');
    else
    {
      if (typeof values == 'object'){
        for(let key in values){
          if(typeof values[key] == 'object')
            this.appendArray(form_data, values[key], name + '[' + key + ']');
          else
            if (values[key] != undefined)
              form_data.append(name + '[' + key + ']', values[key]);
        }
      }
      else
        form_data.append(name, values);
    }

    return form_data;
  }


}
