import api from '@/libs/axios'
import { PartiePrenanteMilieuTravail } from '../models/actionsMilieuTravail/partiePrenanteMilieuTravail';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'PartiesPrenantesActionsMilieuTravail'

export default class PartiesPrenantesMilieuTravailRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, includeType?: boolean): Promise<PaginatedList<PartiePrenanteMilieuTravail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (includeType) params.append('IncludeType', includeType.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
