import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import {
  ActionMilieuTravailList,
  CreateActionMilieuTravailEtudePosteModel,
  CreateActionMilieuTravailModel,
} from "../models/actionsMilieuTravail/actionMilieuTravail";
import { TypeCodeActionMilieuTravail } from "../models/actionsMilieuTravail/typeActionMilieuTravail";
import { NiveauxAlertEntrepriseCreateModelPost } from "../models/options/entreprises/actionMilieuTravail/niveauxAlerteEntreprise";
import { RisqueEntrepriseToSave } from "../models/options/entreprises/actionMilieuTravail/risquesEntreprise";
import { ValeurMesureEntrepriseCreateModel } from "../models/options/entreprises/actionMilieuTravail/valeursMesuresEntreprise";

const baseResource = "Entreprises";
const resource = "ActionsMilieuTravail";

export default class ActionsMilieuTravailResource {
  
  public async paginatedList(
    entrepriseId: string,
    siteId?: string,
    pagenumber?: number,
    pagesize?: number
  ): Promise<PaginatedList<ActionMilieuTravailList>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (siteId) params.append("SiteId", siteId);

    const response = await api.get(
      `${baseResource}/${entrepriseId}/${resource}`,
      { params: params }
    );
    return response.data;
  }

  appendArray(form_data: any, values: any, name: any) {
    if (!values && name) form_data.append(name, "");
    else {
      if (typeof values == "object") {
        for (let key in values) {
          if (typeof values[key] == "object")
            this.appendArray(form_data, values[key], name + "[" + key + "]");
          else if (values[key] != undefined)
            form_data.append(name + "[" + key + "]", values[key]);
        }
      } else form_data.append(name, values);
    }

    return form_data;
  }

  public async create(
    entrepriseId: string,
    data: CreateActionMilieuTravailModel,
    config: any
  ): Promise<string> {
    let formData = new FormData();
    data.fichiers?.forEach((file: File) => formData.append("fichiers", file));
    if (data.commentaire) formData.append("commentaire", data.commentaire);
    if (data.dateAction) formData.append("dateAction", data.dateAction);
    if (data.typeId) formData.append("typeId", data.typeId.toString());
    if (data.typeCode) formData.append("typeCode", data.typeCode.toString());

    if (data.typeCode == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
      if (data.etudeDePoste!.allEntreprise)
        formData.append(
          "EtudePoste.allEntreprise",
          data.etudeDePoste!.allEntreprise.toString()
        );
      if (data.etudeDePoste!.typeDePosteId)
        formData.append(
          "EtudePoste.typeDePosteId",
          data.etudeDePoste!.typeDePosteId.toString()
        );
      if (data.etudeDePoste!.prescripteurId)
        formData.append(
          "EtudePoste.prescripteurId",
          data.etudeDePoste!.prescripteurId.toString()
        );
      if (data.etudeDePoste!.duree)
        formData.append(
          "EtudePoste.duree",
          data.etudeDePoste!.duree.toString()
        );
      if (data.etudeDePoste!.stress)
        formData.append(
          "EtudePoste.stress",
          data.etudeDePoste!.stress.toString()
        );
      if (data.etudeDePoste!.secteurActiviteId)
        formData.append(
          "EtudePoste.secteurActiviteId",
          data.etudeDePoste!.secteurActiviteId.toString()
        );
      data.etudeDePoste!.niveauxAlertes?.forEach(
        (niveauxAlerte: NiveauxAlertEntrepriseCreateModelPost, index) => {
          this.appendArray(
            formData,
            niveauxAlerte,
            `EtudePoste.niveauxAlertes[${index}]`
          );
        }
      );
      data.etudeDePoste!.valeursMesures?.forEach(
        (valeurMesure: ValeurMesureEntrepriseCreateModel, index) => {
          this.appendArray(
            formData,
            valeurMesure,
            `EtudePoste.valeursMesures[${index}]`
          );
        }
      );
      data.etudeDePoste!.risques?.forEach(
        (risque: RisqueEntrepriseToSave, index) => {
          this.appendArray(formData, risque, `EtudePoste.risques[${index}]`);
        }
      );
      data.etudeDePoste!.risquesIds?.forEach((id: number) =>
        formData.append("EtudePoste.risquesIds", id.toString())
      );
      data.etudeDePoste!.binomesId?.forEach((id: number) =>
        formData.append("EtudePoste.binomesId", id.toString())
      );
      data.etudeDePoste!.contextesId?.forEach((id: number) =>
        formData.append("EtudePoste.contextesId", id.toString())
      );

      if (data.etudeDePoste!.branchesId) {
        data.etudeDePoste!.branchesId.forEach((brancheId: string) => {
          formData.append("EtudePoste.branchesId", brancheId.toString());
        });
      }

      if (data.etudeDePoste!.sitesId) {
        data.etudeDePoste!.sitesId.forEach((siteId: string) => {
          formData.append("EtudePoste.sitesId", siteId.toString());
        });
      }

      if (data.etudeDePoste!.regionsId) {
        data.etudeDePoste!.regionsId.forEach((regionId: string) => {
          formData.append("EtudePoste.regionsId", regionId.toString());
        });
      }

      if (data.etudeDePoste!.secteursId) {
        data.etudeDePoste!.secteursId.forEach((secteurId: string) => {
          formData.append("EtudePoste.secteursId", secteurId.toString());
        });
      }

      if (data.etudeDePoste!.tagsId) {
        data.etudeDePoste!.tagsId.forEach((tagId: string) => {
          formData.append("EtudePoste.tagsId", tagId.toString());
        });
      }

      if (data.etudeDePoste!.dossiersId) {
        data.etudeDePoste!.dossiersId.forEach((dossierId: string) => {
          formData.append("EtudePoste.dossierSId", dossierId.toString());
        });
      }
    } else {

      if (data.actionClassique!.allEntreprise)
      formData.append(
        "ActionClassique.allEntreprise",
        data.actionClassique!.allEntreprise.toString()
      );

      if (data.actionClassique!.libelle)
        formData.append(
          "ActionClassique.libelle",
          data.actionClassique!.libelle
        );
      if (data.actionClassique!.nbEmployes)
        formData.append(
          "ActionClassique.nbEmployes",
          data.actionClassique!.nbEmployes.toString()
        );
      if (data.actionClassique!.duree)
        formData.append(
          "ActionClassique.duree",
          data.actionClassique!.duree.toString()
        );
      if (data.actionClassique!.dureeHorsVacation)
        formData.append(
          "ActionClassique.dureeHorsVacation",
          data.actionClassique!.dureeHorsVacation.toString()
        );

      if (data.actionClassique!.partiesPrenantesId) {
        data.actionClassique!.partiesPrenantesId.forEach((ppId: number) => {
          formData.append(
            "ActionClassique.partiesPrenantesId",
            ppId.toString()
          );
        });
      }

      if (data.actionClassique!.branchesId) {
        data.actionClassique!.branchesId.forEach((brancheId: string) => {
          formData.append("ActionClassique.branchesId", brancheId.toString());
        });
      }

      if (data.actionClassique!.sitesId) {
        data.actionClassique!.sitesId.forEach((siteId: string) => {
          formData.append("ActionClassique.sitesId", siteId.toString());
        });
      }

      if (data.actionClassique!.regionsId) {
        data.actionClassique!.regionsId.forEach((regionId: string) => {
          formData.append("ActionClassique.regionsId", regionId.toString());
        });
      }

      if (data.actionClassique!.secteursId) {
        data.actionClassique!.secteursId.forEach((secteurId: string) => {
          formData.append("ActionClassique.secteursId", secteurId.toString());
        });
      }
    }

    const response = await api.post(
      `${baseResource}/${entrepriseId}/${resource}`,
      formData,
      config
    );
    return response.data;
  }

  public async delete(entrepriseId: string, id: string): Promise<void> {
    const response = await api.delete(
      `${baseResource}/${entrepriseId}/${resource}/${id}`
    );
    return response.data;
  }

  public async getById(entrepriseId: string, id: string) {
    const response = await api.get(
      `${baseResource}/${entrepriseId}/${resource}/${id}`
    );
    return response.data;
  }

  public async put(
    entrepriseId: string,
    id: string,
    data: CreateActionMilieuTravailModel,
    config: any
  ): Promise<string> {
    let formData = new FormData();
    formData.append("id", id);
    data.fichiers?.forEach((file: File) => formData.append("fichiers", file));
    if (data.commentaire) formData.append("commentaire", data.commentaire);
    if (data.dateAction) formData.append("dateAction", data.dateAction);
    if (data.typeId) formData.append("typeId", data.typeId.toString());
    if (data.typeCode) formData.append("typeCode", data.typeCode.toString());

    if (
      data.typeCode == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE &&
      data.etudeDePoste
    ) {
      
      if (data.etudeDePoste.etudeDePosteId)
        formData.append(
          "etudeDePosteId",
          data.etudeDePoste.etudeDePosteId.toString()
        );

      if (data.etudeDePoste.typeDePosteId)
        formData.append(
          "typeDePosteId",
          data.etudeDePoste.typeDePosteId.toString()
        );
      if (data.etudeDePoste.prescripteurId)
        formData.append(
          "prescripteurId",
          data.etudeDePoste.prescripteurId.toString()
        );
      if (data.etudeDePoste.stress)
        formData.append("stress", data.etudeDePoste.stress.toString());

      if (data.etudeDePoste.secteurActiviteId)
        formData.append(
          "secteurActiviteId",
          data.etudeDePoste.secteurActiviteId.toString()
        );

      data.etudeDePoste.niveauxAlertes?.forEach(
        (niveauxAlerte: NiveauxAlertEntrepriseCreateModelPost, index) => {
          this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`);
        }
      );

      data.etudeDePoste.niveauxAlertes?.forEach(
        (niveauxAlerte: NiveauxAlertEntrepriseCreateModelPost, index) => {
          this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`);
        }
      );
      data.etudeDePoste.valeursMesures?.forEach(
        (valeurMesure: ValeurMesureEntrepriseCreateModel, index) => {
          this.appendArray(formData, valeurMesure, `valeursMesures[${index}]`);
        }
      );
      data.etudeDePoste.risques?.forEach(
        (risque: RisqueEntrepriseToSave, index) => {
          this.appendArray(formData, risque, `risques[${index}]`);
        }
      );
      data.etudeDePoste.binomesId?.forEach((id: number) =>
        formData.append("binomesId", id.toString())
      );
      data.etudeDePoste.contextesId?.forEach((id: number) =>
        formData.append("contextesId", id.toString())
      );
      data.etudeDePoste.risquesIds?.forEach((id: any) =>
        formData.append("risquesIds", id.toString())
      );
    } else {
      if (data.actionClassique!.libelle)
        formData.append(
          "ActionClassique.libelle",
          data.actionClassique!.libelle
        );
      if (data.actionClassique!.nbEmployes)
        formData.append(
          "ActionClassique.nbEmployes",
          data.actionClassique!.nbEmployes.toString()
        );
      if (data.actionClassique!.duree)
        formData.append(
          "ActionClassique.duree",
          data.actionClassique!.duree.toString()
        );
      if (data.actionClassique!.dureeHorsVacation)
        formData.append(
          "ActionClassique.dureeHorsVacation",
          data.actionClassique!.dureeHorsVacation.toString()
        );

      if (data.actionClassique!.partiesPrenantesId) {
        data.actionClassique!.partiesPrenantesId.forEach((ppId: number) => {
          formData.append(
            "ActionClassique.partiesPrenantesId",
            ppId.toString()
          );
        });
      }

      if (data.actionClassique!.branchesId) {
        data.actionClassique!.branchesId.forEach((brancheId: string) => {
          formData.append("ActionClassique.branchesId", brancheId.toString());
        });
      }

      if (data.actionClassique!.sitesId) {
        data.actionClassique!.sitesId.forEach((siteId: string) => {
          formData.append("ActionClassique.sitesId", siteId.toString());
        });
      }

      if (data.actionClassique!.regionsId) {
        data.actionClassique!.regionsId.forEach((regionId: string) => {
          formData.append("ActionClassique.regionsId", regionId.toString());
        });
      }

      if (data.actionClassique!.secteursId) {
        data.actionClassique!.secteursId.forEach((secteurId: string) => {
          formData.append("ActionClassique.secteursId", secteurId.toString());
        });
      }
    }

    const response = await api.put(
      `${baseResource}/${entrepriseId}/${resource}/${id}`,
      formData,
      config
    );
    return response.data;
  }

  public async deleteFile(
    entrepriseId: string,
    id: string,
    fileId: string
  ): Promise<void> {
    const response = await api.delete(
      `${baseResource}/${entrepriseId}/${resource}/${id}/Files/${fileId}`
    );
    return response.data;
  }

  // public async putEntreprise(entrepriseId: string, data : TransfertDossiersSecteurSearchParams) : Promise<any> {
  //   const params = {
  //     entrepriseId: data.entrepriseId,
  //     newEntrepriseId: data.newEntrepriseId
  //   }

  //   const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/Entreprises`, params)
  //   return response.data
  // }

  // public async getActionsCollectivesEntrepriseCount(entrepriseId: string): Promise<number> {
  //   const params = new URLSearchParams();
  //   params.append('EntrepriseId', entrepriseId.toString());

  //   const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/Entreprises/Count`, { params: params })
  //   return response.data
  // }
}
