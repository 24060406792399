export default [
    {
      path: '/rapport-activites',
      name: 'rapport-activites',
      component: () => import('@/views/rapport-activites/RapportActivites.vue'),
      meta: {
        pageTitle: 'Rapport d\'activites',
        breadcrumb: [
          {
            text: 'Rapport d\'activités',
            active: true,
          },
        ],
      },
    },

  ]