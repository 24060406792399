import api from '@/libs/axiosMyRessif'
import { PaginatedList } from '../models/common/paginatedList'
import { ChangelogApplication } from '../models/enums/changelogApplication';


const resource = 'changelogsApplication'

export default class ChangelogsResource {
  public async paginatedList(application: string, tenantId: string, search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<ChangelogApplication>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (application) params.append('Application', application.toString()); 
    if (tenantId) params.append('TenantId', tenantId.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
