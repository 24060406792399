import api from '@/libs/axios'

const resource = 'MyProfile'

export default class MyProfileResource {
  public async get() : Promise<any> {
    const response = await api.get(`${resource}`)
    return response.data
  }
}
