import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { TransfertDossiersSecteurSearchParams } from '../models/dossiers/dossier';
import { ActionAdministratifList, CreateActionAdministratifModel } from '../models/actionsAdministratif/actionAdministratif';

const baseResource = 'Entreprises'
const resource = 'ActionsAdministratif'

export default class ActionsCollectivesResource {
  public async paginatedList(entrepriseId: string, siteId?: string, pagenumber?: number, pagesize?: number): Promise<PaginatedList<ActionAdministratifList>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (siteId) params.append('SiteId', siteId);

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params })
    return response.data
  }

  appendArray(form_data: any, values: any, name: any){
    if (!values && name)
      form_data.append(name, '');
    else
    {
        if (typeof values == 'object'){
          for(let key in values){
            if(typeof values[key] == 'object')
              this.appendArray(form_data, values[key], name + '[' + key + ']');
            else
              if (values[key] != undefined)
              form_data.append(name + '[' + key + ']', values[key]);
          }
        }
        else
            form_data.append(name, values);
    }

    return form_data;
  }

  public async create(entrepriseId: string, data: CreateActionAdministratifModel, config: any): Promise<string> {
    let formData = new FormData();

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.libelle) formData.append('libelle', data.libelle)
    if (data.typeId) formData.append('typeId', data.typeId.toString())
    if (data.nbEmployes) formData.append('nbEmployes', data.nbEmployes.toString())
    if (data.duree) formData.append('duree', data.duree.toString())
    if (data.dureeHorsVacation) formData.append('dureeHorsVacation', data.dureeHorsVacation.toString())
    if (data.dateAction) formData.append('dateAction', data.dateAction)
    
    

    if (data.partiesPrenantesId) {
      data.partiesPrenantesId.forEach((ppId: number) => {
        formData.append('partiesPrenantesId', ppId.toString())
      })
    }

    if (data.branchesId) {
      data.branchesId.forEach((brancheId: string) => {
        formData.append('branchesId', brancheId.toString())
      })
    }

    if (data.sitesId) {
      data.sitesId.forEach((siteId: string) => {
        formData.append('sitesId', siteId.toString())
      })
    }

    if (data.regionsId) {
      data.regionsId.forEach((regionId: string) => {
        formData.append('regionsId', regionId.toString())
      })
    }

    if (data.secteursId) {
      data.secteursId.forEach((secteurId: string) => {
        formData.append('secteursId', secteurId.toString())
      })
    }

    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(entrepriseId: string, id: string) : Promise<void> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async getById(entrepriseId: string, id: string) {
    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async put(entrepriseId: string, id: string, data: CreateActionAdministratifModel, config: any): Promise<string> {
    let formData = new FormData();
    formData.append('id', id)

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.libelle) formData.append('libelle', data.libelle)
    if (data.typeId) formData.append('typeId', data.typeId.toString())
    if (data.nbEmployes) formData.append('nbEmployes', data.nbEmployes.toString())
    if (data.duree) formData.append('duree', data.duree.toString())
    if (data.dureeHorsVacation) formData.append('dureeHorsVacation', data.dureeHorsVacation.toString())
    if (data.dateAction) formData.append('dateAction', data.dateAction)
    
    if (data.partiesPrenantesId) {
      data.partiesPrenantesId.forEach((ppId: number) => {
        formData.append('partiesPrenantesId', ppId.toString())
      })
    }
    if (data.branchesId) {
      data.branchesId.forEach((brancheId: string) => {
        formData.append('branchesId', brancheId)
      })
    }

    if (data.sitesId) {
      data.sitesId.forEach((siteId: string) => {
        formData.append('sitesId', siteId)
      })
    }
    if (data.regionsId) {
      data.regionsId.forEach((regionId: string) => {
        formData.append('regionsId', regionId)
      })
    }
    if (data.secteursId) {
      data.secteursId.forEach((secteurId: string) => {
        formData.append('secteursId', secteurId)
      })
    }

    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, formData, config)
    return response.data
  }

  public async deleteFile(entrepriseId: string, id: string, fileId: string) : Promise<void> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }

  // public async putEntreprise(entrepriseId: string, data : TransfertDossiersSecteurSearchParams) : Promise<any> {
  //   const params = {
  //     entrepriseId: data.entrepriseId,
  //     newEntrepriseId: data.newEntrepriseId
  //   }

  //   const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/Entreprises`, params)
  //   return response.data
  // }

  // public async getActionsCollectivesEntrepriseCount(entrepriseId: string): Promise<number> {
  //   const params = new URLSearchParams();
  //   params.append('EntrepriseId', entrepriseId.toString());

  //   const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/Entreprises/Count`, { params: params })
  //   return response.data
  // }
}
