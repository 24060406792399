import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Maladie } from '../models/options/maladies/maladie'

const resource = 'Maladies'

export default class MaladiesRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifonly?: boolean, search?: string): Promise<PaginatedList<Maladie>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifonly) params.append('ActifOnly', actifonly.toString()); 
    if (search) params.append('Search', search.toString()); 

    const response = await api.get(`${resource}`, { params: params })
    return response.data
  }
}
