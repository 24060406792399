export default [
  {
    path: '/accompagnement-individuel',
    name: 'accompagnement-individuel',
    component: () => import('@/views/accompagnement-individuel/Index.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/create',
    name: 'create-accompagnement-individuel',
    component: () => import('@/views/accompagnement-individuel/createDossier/Create.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Créer un dossier',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/importDossiers',
    name: 'import-dossiers-accompagnement-individuel',
    component: () => import('@/views/accompagnement-individuel/importDossiers/Index.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Importer des dossiers',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/create-rapide',
    name: 'create-accompagnement-individuel-rapide',
    component: () => import('@/views/accompagnement-individuel/createDossierRapide/Create.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Créer un dossier',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/create-no-dossier',
    name: 'create-nouveau-sans-dossier',
    component: () => import('@/views/accompagnement-individuel/createNouveauSansDossier/Create.vue'),
    meta: {
      pageTitle: 'Création d\'une action sans dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Création d\'une action sans dossier',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/list',
    name: 'list-accompagnement-individuel',
    component: () => import('@/views/accompagnement-individuel/searchDossier/ListeDossiers.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Recherche avancée',
          active: true,
        },
      ],
      action: 'read',
      resource: 'ACL',
      rights: [
        "DOSSIER_INFO_GENERAL",
      ]
    },
  },
  {
    path: '/accompagnement-individuel/details/:id',
    name: 'details-accompagnement-individuel',
    component: () => import('@/views/accompagnement-individuel/detailsDossier/DetailsDossier.vue'),
    meta: {
      pageTitle: 'Suivi de dossier',
      breadcrumb: [
        {
          text: 'Suivi de dossier',
        },
        {
          text: 'Consultation d\' un dossier',
          active: true,
        },
      ],
      action: 'read',
      resource: 'ACL',
      rights: [
        "DOSSIER_INFO_GENERAL"
      ]
    },
  },
  {
    path: '/confirmRGPD/:id/:timestamp',
    name: 'user-confirm-rgpd',
    component: () => import('@/views/administration/rgpd/ConfirmRgpd.vue'),
    meta: {
      layout: 'full',
    }
  }
]
