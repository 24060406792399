import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { CategorieOrientation } from '../models/dossiers/categorieOrientation';

const resource = 'CategoriesOrientations'

export default class CategoriesOrientationsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifonly?: boolean) : Promise<PaginatedList<CategorieOrientation>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifonly) params.append('ActifOnly', actifonly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
