export interface TypeActionMilieuTravail{
  id: number
  libelle: string
  code: string
  ordre: number
  actif: boolean
  requiringLabel: boolean
  requiringImpact: boolean
  requiringDureeVacation: boolean
}

export enum TypeCodeActionMilieuTravail {
  ETUDE_DE_POSTE='EDPE',
  ACTION_CLASSIQUE='AMT_AC'
}