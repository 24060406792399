import api from '@/libs/axios';
import { PaginatedList } from "../models/common/paginatedList";
import { Habilitation, HabilitationCreateModel, HabilitationUpdateModel } from "../models/options/habilitations/habilitation";

const baseResource = 'Dossiers'
const resource = 'Habilitations'

export default class HabilitationsResource {
    public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number): Promise<PaginatedList<Habilitation>> {
      const params = new URLSearchParams();
      if (pagenumber) params.append('PageNumber', pagenumber.toString());
      if (pagesize) params.append('PageSize', pagesize.toString());
  
      const response = await api.get(`${baseResource}/${dossierId}/SituationProfessionnelle/${resource}`, { params: params })
      return response.data
    }

    public async get(dossierId: string, id: string): Promise<Habilitation> {
      const response = await api.get(`${baseResource}/${dossierId}/SituationProfessionnelle/${resource}/${id}`);
      return response.data;
    }

    public async post(dossierId: string, data : HabilitationCreateModel) : Promise<Habilitation> {
      console.log(data);
      let formData = new FormData();
  
      if (data.dossierId) formData.append('dossierId', data.dossierId.toString())
      if (data.dateHabilitation) formData.append('datehabilitation', data.dateHabilitation)
      if (data.dateFin) formData.append('dateFin', data.dateFin)
      if (data.typeHabilitationId) formData.append('typeHabilitationId', data.typeHabilitationId.toString())
      if (data.typeHabilitationCustom) formData.append('typeHabilitationCustom', data.typeHabilitationCustom.toString())
      if (data.consigneEcheanceHabilitationActivee) formData.append('consigneEcheanceHabilitationActivee', data.consigneEcheanceHabilitationActivee.toString())
      if (data.dateConsigneEcheanceHabilitation) formData.append('dateConsigneEcheanceHabilitation', data.dateConsigneEcheanceHabilitation.toString())
  
      const response = await api.post(`${baseResource}/${dossierId}/SituationProfessionnelle/${resource}`, formData)
      return response.data
    }

    public async put(dossierId: string, id: string, data : HabilitationUpdateModel) : Promise<Habilitation> {
      console.log(data);
      let formData = new FormData();

      if (data.dossierId) formData.append('dossierId', dossierId)
      if (data.id) formData.append('id', id)
      if (data.dateHabilitation) formData.append('datehabilitation', data.dateHabilitation)
      if (data.dateFin) formData.append('dateFin', data.dateFin)
      if (data.typeHabilitationId) formData.append('typeHabilitationId', data.typeHabilitationId.toString())
      if (data.typeHabilitationCustom) formData.append('typeHabilitationCustom', data.typeHabilitationCustom.toString())
      if (data.consigneEcheanceHabilitationActivee) formData.append('consigneEcheanceHabilitationActivee', data.consigneEcheanceHabilitationActivee.toString())
      if (data.dateConsigneEcheanceHabilitation) formData.append('dateConsigneEcheanceHabilitation', data.dateConsigneEcheanceHabilitation.toString())
  
      const response = await api.put(`${baseResource}/${dossierId}/SituationProfessionnelle/${resource}/${id}`, formData)
      return response.data
    }

    public async delete(dossierId: string, id : string) : Promise<Habilitation> {
      const response = await api.delete(`${baseResource}/${dossierId}/SituationProfessionnelle/${resource}/${id}`)
      return response.data
    }
  }