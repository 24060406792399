import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { CharteGraphique, CouleurCharteGraphique, NewCharteGraphique, UpdateCharteGraphique, UpdateCouleurCharteGraphique } from '../models/options/charteGraphique/charteGraphique'

const resource = 'CharteGraphique'

export default class CharteGraphiqueRessource {
 
  public async paginatedList( pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<CharteGraphique>> {
    const params = new URLSearchParams();

    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search && search.length) params.append('Search', search?.toString());

    const response = await api.get(`${resource}`, { params: params })
    return response.data
  }
  public async addColor(charteId:string,data : CouleurCharteGraphique) : Promise<CharteGraphique> {
    const response = await api.post(`${resource}/${charteId}/CouleurCharteGraphique`, data)
    return response.data
  }

  public async removeColor(charteId:string,couleurId : string) : Promise<CharteGraphique> {
    const response = await api.delete(`${resource}/${charteId}/CouleurCharteGraphique/${couleurId}`)
    return response.data
  }

  public async updateColor(charteId:string,data : UpdateCouleurCharteGraphique) : Promise<CouleurCharteGraphique> {
    const response = await api.put(`${resource}/${charteId}/CouleurCharteGraphique/${data.id}` , data)
    return response.data
  }
  
  public async post(data : NewCharteGraphique) : Promise<CharteGraphique> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async put(id: string, data:UpdateCharteGraphique): Promise<CharteGraphique> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<CharteGraphique> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  
  public async getById( id: string): Promise<CharteGraphique> {
    const response = await api.get(`${resource}/${id}`)
    return response.data
  }

  public async getByTenantId( id: string): Promise<CharteGraphique> {
    const response = await api.get(`${resource}/tenant/${id}`)
    return response.data
  }

}
