import api from '@/libs/axios'

const resource = 'Statistiques'

export default class StatistiquesResource {
  public async crl(data: any) : Promise<any> {
    const response = await api.post(`${resource}/crl`, data, { responseType: 'arraybuffer', timeout: 300000 })
    return response.data
  }

  public async dossierInstruit(data: any) : Promise<any> {
    const response = await api.post(`${resource}/dossierInstruit`, data, { responseType: 'arraybuffer', timeout: 300000 })
    return response.data
  }

  public async generale(data: any) : Promise<any> {
    const response = await api.post(`${resource}/generale?data=${data.code}`, data, { responseType: 'arraybuffer', timeout: 300000 })

    return response.data
  }

  public async pilotage(data: any) : Promise<any> {
    const response = await api.post(`${resource}/pilotage`, data)
    return response.data
  }

  public async facturation(data: any) : Promise<any> {
    const response = await api.post(`${resource}/facturation`, data, { responseType: 'arraybuffer', timeout: 300000 })
    return response.data
  }

  public async entreprisesByUser() : Promise<any> {
    const response = await api.get(`${resource}/entreprisesByUser`)
    return response.data
  }
}
