import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Horaire } from '../models/options/horaires/horaire'

const resource = 'Horaires'

export default class HorairesRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifonly?: boolean): Promise<PaginatedList<Horaire>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifonly) params.append('ActifOnly', actifonly.toString()); 

    const response = await api.get(`${resource}`, { params: params })
    return response.data
  }

  public async post(data: Horaire): Promise<Horaire> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id: string): Promise<Horaire> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async put(id: string, data: Horaire): Promise<Horaire> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }
}
