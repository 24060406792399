import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import authService from '@/auth/auth.service'

export default function usePreferencesUtilisateur() {

    const listPreferencesUtilisateur = computed({
        get: () => store.state.user.preferences_utilisateur
      })
      
      const fetchPreferencesUtilisateur = async () => {
        const accessToken = await authService.getAccessToken()
        const tokenExpired = await authService.willTokenExpire()
    
        if (accessToken && !tokenExpired) {
          store.dispatch('user/fetchPreferencesUtilisateur')
        }
      }
      
      return {
        listPreferencesUtilisateur, 
        fetchPreferencesUtilisateur,    
      }
}