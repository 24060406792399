import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { ThemePreventionSanteTravail } from '../models/preventionSanteTravail/themePreventionSanteTravail';

const resource = 'ThemesActionPreventionSanteTravail'

export default class ThemesPreventionSanteTravailRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string, actifOnly?: boolean) : Promise<PaginatedList<ThemePreventionSanteTravail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (actifOnly) params.append('ActifOnly', actifOnly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
