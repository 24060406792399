import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { DemandeLogement } from '../models/dossiers/demandesLogement/demandeLogement'

const resource = 'DemandesLogement'
const baseResource = 'dossier'

export default class DemandeLogementRessources {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<DemandeLogement>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search && search.length) params.append('Search', search?.toString());
    
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params } )
    return response.data
  }

  public async post(dossierId : string, demande: DemandeLogement, config: any) : Promise<DemandeLogement> {
    let formData = new FormData();

    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateDemandePret) formData.append('dateDemandePret', demande.dateDemandePret)
    if (demande.organismeDemandeLogementId) formData.append('organismeDemandeLogementId', demande.organismeDemandeLogementId)
    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(dossierId : string, id : string) : Promise<DemandeLogement> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async put(dossierId : string, id: string, demande : DemandeLogement, config : any) : Promise<DemandeLogement> {
    let formData = new FormData();

    formData.append('id', id)
    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateDemandePret) formData.append('dateDemandePret', demande.dateDemandePret)
    if (demande.organismeDemandeLogementId) formData.append('organismeDemandeLogementId', demande.organismeDemandeLogementId)
    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, formData, config)
    return response.data
  }

  public async getById(dossierId : string, id : string) : Promise<DemandeLogement> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, fileId: string) : Promise<DemandeLogement> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}
