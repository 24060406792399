import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Equipement } from '../models/dossiers/equipement'

const resource = 'Equipements'

export default class EquipementsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string, equipements?: any[]) : Promise<PaginatedList<Equipement>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    if (equipements && equipements.length) params.append('Equipements', equipements.toString()) 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
