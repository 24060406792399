import api from '@/libs/axios'
import { InformationSante, UpdateInformationSanteModel } from '../models/dossiers/informationSante'

const baseResource = 'Dossiers'
const resource = 'InformationsSante'

export default class InformationsSanteResource {
  public async get(dossierId: string) : Promise<InformationSante> {

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async put(dossierId : string, id: string, data : UpdateInformationSanteModel) : Promise<InformationSante> {
    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, data)
    return response.data
  }

  public async deleteMaladieProfessionnelle(dossierId : string, id: string, maladieProId : string) : Promise<InformationSante> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/maladieProfessionnelle/${maladieProId}`)
    return response.data
  }

  public async addMaladieProfessionnelle(dossierId : string, id: string, maladieProId : string) : Promise<InformationSante> {
    const response = await api.post(`${baseResource}/${dossierId}/${resource}/${id}/maladieProfessionnelle/`, {id: maladieProId})
    return response.data
  }
}
