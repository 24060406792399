import appApiTypes from '@/api'
import user from '../user'


function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    consignes: localStorage.getItem('consignes') && IsJsonString(localStorage.getItem('consignes')) ? JSON.parse(localStorage.getItem('consignes')) : null,

  },
  getters: {
    consignes: state => state.consignes,
  },

  mutations: {
    SET_CONSIGNES(state, consignes) {
      localStorage.setItem('consignes', JSON.stringify(consignes))
      state.consignes = consignes
    }
  },
  actions: {
    setConsignes({ commit }, consignes) {
      commit('SET_CONSIGNES', consignes)
    },
    fetchConsigne({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.destinataires
          .get(user.state.user.uid, false, 1, 4)
          .then(response => { 
            commit('SET_CONSIGNES', response)
          })
          .catch(error => {
            console.log(error);
            reject(error)
          });

      })
    }
  }
}