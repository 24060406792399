import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { UserDetail } from '../models/authorization/userDetails/userDetail'
import { TransfertDossierProfilSearchParams } from '../models/dossiers/dossier';

const resource = 'UserDetails'

export default class UserDetailsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, nomSearch?: string, prenomSearch?: string, ssieSearch?: string, identifiantSearch?: string, onlyActif?: boolean) : Promise<PaginatedList<UserDetail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (nomSearch && nomSearch.length) params.append('Nom', nomSearch?.toString()); 
    if (prenomSearch && prenomSearch.length) params.append('Prenom', prenomSearch?.toString()); 
    if (ssieSearch && ssieSearch.length) params.append('SSIE', ssieSearch?.toString()); 
    if (identifiantSearch && identifiantSearch.length) params.append('Identifiant', identifiantSearch?.toString()); 
    if (onlyActif) params.append('OnlyActif', onlyActif.toString());

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async get(id: string) : Promise<UserDetail> { 
    const response = await api.get(`${resource}/${id}` )
    return response.data
  }
}
