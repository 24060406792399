import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { ImpactEntreprise } from '../models/options/entreprises/actionMilieuTravail/impactsEntreprise';

const resource = 'ImpactsEntreprise'

export default class ImpactsEntrepriseRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<ImpactEntreprise>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getImpactsByRisques(risques : any){
    const params = new URLSearchParams();
    risques.forEach( (r:any) => {
        params.append('risqueId', r.id)
    })
    const response = await api.get(`${resource}/risques`, { params: params } )
    return response.data
  }
}
