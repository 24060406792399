import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { ProjetPreventionSantePublique } from '../models/preventionSantePublique/projetPreventionSantePublique'

const baseResource = 'entreprises'
const resource = 'ProjetsActionPreventionSantePublique'

export default class ProjetsPreventionSantePubliqueResource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<ProjetPreventionSantePublique>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params } )
    return response.data
  }

  public async getById(entrepriseId: string, id : string) : Promise<ProjetPreventionSantePublique> {
    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async delete(entrepriseId: string, id : string) : Promise<ProjetPreventionSantePublique> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async post(entrepriseId: string, data : ProjetPreventionSantePublique) : Promise<ProjetPreventionSantePublique> {
    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, data)
    return response.data
  }

  public async put(entrepriseId: string, id : string, data : ProjetPreventionSantePublique) : Promise<ProjetPreventionSantePublique> {
    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, data)
    return response.data
  }
}
