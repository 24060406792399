import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { TypeInterlocuteur } from '../models/options/typeInterlocuteurs/typeInterlocuteur';

const resource = 'TypesInterlocuteur'

export default class TypesInterlocuteurRessources {
  public async paginatedList(typeInterventionId: string, externe: boolean, pagenumber?: number, pagesize?: number, search?: string, actifOnly?: boolean) : Promise<PaginatedList<TypeInterlocuteur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (actifOnly) params.append('ActifOnly', actifOnly.toString());
    if (typeInterventionId && typeInterventionId.length) params.append('TypeInterventionId', typeInterventionId?.toString());
    params.append('Externe', externe.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
