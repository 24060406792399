import api from '@/libs/axios'
import { UserDetail } from '../models/authorization/userDetails/userDetail';
import { PaginatedList } from '../models/common/paginatedList';
import { ConsigneCreateModel, ListeConsignes, Consigne } from '../models/dossiers/consignes';
import { Equipe } from '../models/options/equipes/equipe';

const resource = 'consignes'
const dossierRessources= 'dossiers'
const entrepriseRessources= 'entreprises'

export default class ActionsResources {
  public async paginatedListEquipes(pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<Equipe>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search) params.append('Search', search.toString());

    const response = await api.get(`${resource}/equipesSelectionnables`, { params: params })
    return response.data
  }

  public async paginatedListMembres(pagenumber?: number, pagesize?: number, search?: string, actif: boolean = true): Promise<PaginatedList<UserDetail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search) params.append('Search', search.toString());
    if (actif) params.append('Actif', actif.toString()); 

    const response = await api.get(`${resource}/membresSelectionnables`, { params: params })
    return response.data
  }

  public async listConsignesDossier(dossierId: number, request: ListeConsignes): Promise<PaginatedList<Consigne>> {
    const params = new URLSearchParams();
    if (request.dossierId) params.append('DossierId', request.dossierId.toString());
    if (request.onlyTraitee) params.append('IsTraitee', request.onlyTraitee.toString());

    const response = await api.get(`${dossierRessources}/${dossierId}/ConsignesDossier`, { params: params })
    return response.data;
  }

  public async listConsignesEntreprise(entrepriseId: number, request: ListeConsignes): Promise<PaginatedList<Consigne>> {
    const params = new URLSearchParams();
    if (request.currentPage) params.append('PageNumber', request.currentPage.toString());
    if (request.perPage) params.append('PageSize', request.perPage.toString());
    if (request.entrepriseId) params.append('EntrepriseId', request.entrepriseId.toString());
    if (request.onlyTraitee) params.append('IsTraitee', request.onlyTraitee.toString());

    const response = await api.get(`${entrepriseRessources}/${entrepriseId}/ConsignesEntreprise`, { params: params })
    return response.data;
  }

  public async getDetails(id: string): Promise<any> {
    const response = await api.get(`${resource}/${id}/detailsConsigne`)
    return response.data
  }
  
  public async getStatut(dossierId: string): Promise<any> {
    const response = await api.get(`${dossierRessources}/${dossierId}/consignes/statut`)
    return response.data
  }

  public async createForEntreprise(entrepriseId: string, data: ConsigneCreateModel): Promise<any> {
    const response = await api.post(`${entrepriseRessources}/${entrepriseId}/ConsignesEntreprise`, data)
    return response.data
  }

  public async updateTraiteeForConsigneEntreprise(entrepriseId: string, id: string): Promise<any> {
    const response = await api.put(`${entrepriseRessources}/${entrepriseId}/ConsignesEntreprise/${id}/Traiter`)
    return response.data
  }

  public async consignesByEntreprise(entrepriseId: string, pagenumber: number, pagesize: number, traitee: boolean = false): Promise<any> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (entrepriseId) params.append('EntrepriseId', entrepriseId.toString());
    if (traitee) params.append("IsTraitee", traitee.toString());

    const response = await api.get(`${entrepriseRessources}/${entrepriseId}/ConsignesEntreprise`, { params: params })
    return response.data
  }

  public async createForDossier(dossierId: string, data: ConsigneCreateModel): Promise<any> {
    const response = await api.post(`${dossierRessources}/${dossierId}/ConsignesDossier`, data)
    return response.data
  }

  public async updateTraiteeForConsigneDossier(dossierId: string, id: string): Promise<any> {
    const response = await api.put(`${dossierRessources}/${dossierId}/ConsignesDossier/${id}/Traiter`)
    return response.data
  }

  public async consignesByDossier(dossierId: string, pagenumber: number, pagesize: number, traitee: boolean = false): Promise<any> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (dossierId) params.append('DossierId', dossierId.toString());
    if (traitee) params.append("IsTraitee", traitee.toString());

    const response = await api.get(`${dossierRessources}/${dossierId}/ConsignesDossier`, { params: params })
    return response.data
  }
}
