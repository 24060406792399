import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import authService from '@/auth/auth.service'

export default function useUserDroits() {
  const listDroits = computed({
    get: () => store.state.user.droits
  })

  const listFonctions = computed({
    get: () => store.state.user.fonctions_codes
  })

  const fetchDroits = async () => {
    const accessToken = await authService.getAccessToken()
    const tokenExpired = await authService.willTokenExpire()

    if (accessToken && !tokenExpired) {
      store.dispatch('user/fetchDroits')
    }
  }
  
  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource
   * @param {Object} item navigation object item
  */
  const canViewVerticalNavMenuHeader = item => {
    return (!item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1)) && 
    (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
    (!item.fonctions || item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
  }
  
  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource
   * @param {Object} item navigation object item
   */
  const canViewVerticalNavMenuLink = item => {
    return (!item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1)) && 
    (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
    (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
  }

  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource & Hide group if all of it's children are hidden
   * @param {Object} item navigation object item
   */
  // eslint-disable-next-line arrow-body-style
  const canViewVerticalNavMenuGroup = item => {
    // ! This same logic is used in canViewHorizontalNavMenuGroup and canViewHorizontalNavMenuHeaderGroup. So make sure to update logic in them as well
    const hasAnyVisibleChild = item.children.some(i => 
      !i.rights || i?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1) && 
      (!i.notFonctions || i?.notFonctions?.some(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!i.fonctions || i?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
    );

    // If resource and action is defined in item => Return based on children visibility (Hide group if no child is visible)
    // Else check for ability using provided resource and action along with checking if has any visible child
    if (!item.rights && !item.notFonctions && !item.fonctions) {
      return hasAnyVisibleChild
    }
    return (
      !item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1) && 
      (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1)) && 
      hasAnyVisibleChild
    )
  }
  
  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource
   * @param {Object} item navigation object item
   */
  const canViewHorizontalNavMenuLink = item => { 
    return (
      !item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1) &&
      (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
    )
  } 

  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource
   * @param {Object} item navigation object item
   */
  const canViewHorizontalNavMenuHeaderLink = item => { 
    return (
      !item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1) && 
      (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
    )
  }

  /**
   * Check if user can view item based on it's ability
   * Based on item's action and resource & Hide group if all of it's children are hidden
   * @param {Object} item navigation object item
   */
  // eslint-disable-next-line arrow-body-style
  const canViewHorizontalNavMenuGroup = item => {
    // ? Same logic as canViewVerticalNavMenuGroup
    const hasAnyVisibleChild = item.children.some(i => 
      (!i.rights || i?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1)) && 
      (!i.notFonctions || i?.notFonctions?.some(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!i.fonctions ||i?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1))
    )

    // If resource and action is defined in item => Return based on children visibility (Hide group if no child is visible)
    // Else check for ability using provided resource and action along with checking if has any visible child
    if (!item.rights && !item.fonctions && !item.notFonctions) {
      return hasAnyVisibleChild
    }
    return (
      (!item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1)) &&
      (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1)) && 
      hasAnyVisibleChild
    )
  }

  // eslint-disable-next-line arrow-body-style
  const canViewHorizontalNavMenuHeaderGroup = item => {
    // ? Same logic as canViewVerticalNavMenuGroup but with extra content

    // eslint-disable-next-line arrow-body-style
    const hasAnyVisibleChild = item.children.some(grpOrItem => {
      // If it have children => It's grp
      // Call ACL function based on grp/link
      return grpOrItem.children ? canViewHorizontalNavMenuGroup(grpOrItem) : canViewHorizontalNavMenuLink(grpOrItem)
    })

    // If resource and action is defined in item => Return based on children visibility (Hide group if no child is visible)
    // Else check for ability using provided resource and action along with checking if has any visible child
    if (!item.rights && !item.fonctions && !item.notFonctions) {
      return hasAnyVisibleChild
    }
    return (
      (!item.rights || item?.rights?.some(droitRoute => listDroits.value.indexOf(droitRoute) !== -1)) &&
      (!item.notFonctions || item?.notFonctions?.every(notFoncRoute => listFonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!item.fonctions ||item?.fonctions?.some(foncRoute => listFonctions.value.indexOf(foncRoute) !== -1)) && 
      hasAnyVisibleChild
     ) 
  }

  return {
    listFonctions, 
    
    listDroits,
    fetchDroits,

    canViewVerticalNavMenuHeader,
    canViewVerticalNavMenuGroup,
    canViewVerticalNavMenuLink,

    canViewHorizontalNavMenuHeaderGroup,
    canViewHorizontalNavMenuHeaderLink,
    canViewHorizontalNavMenuLink

  }
}
