
















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "display-icon"
})
export default class DisplayIcon extends Vue {
  
}
