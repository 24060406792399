import api from '@/libs/axios'
import { DemandeInvalidite } from '../models/dossiers/demandesInvalidite/demandeInvalidite'
import { DemandeRqth } from '../models/options/demandesRqth/demandeRqth'

const resource = 'DemandesInvalidite'
const baseResource = 'dossier'

export default class DemandeInvaliditeRessources {
  public async get(dossierId: string) : Promise<any> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async put(dossierId : string, demande: DemandeInvalidite, config: any) : Promise<DemandeRqth> {
    let formData = new FormData();

    if (demande.dateCpam) formData.append('dateCpam', demande.dateCpam)
    if (demande.dateAttribution) formData.append('dateAttribution', demande.dateAttribution)
    if (demande.dateDeblocage) formData.append('dateDeblocage', demande.dateDeblocage)
    if (demande.categorieInvaliditeId) formData.append('categorieInvaliditeId', demande.categorieInvaliditeId)

    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, fileId: string) : Promise<any> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}
