export default [
  {
    path: '/changelogs',
    name: 'changelogs',
    component: () => import('@/views/changelogs/Index.vue'),
    meta: {
      pageTitle: 'Changelogs',
      resource: 'ACL',
      action: 'read',
    },
  },
]
