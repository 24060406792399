import api from '@/libs/axios'
import { NouveauSansDossierCreateModel } from '../models/dossiers/dossier'

const resource = 'NouveauSansDossier'

export default class NouveauSansDossiersResource {
  public async post(data : NouveauSansDossierCreateModel) : Promise<NouveauSansDossierCreateModel> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }
}
