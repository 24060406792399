import { UserManager, User } from 'oidc-client'

export default class AuthService {
    userManager: UserManager;

    constructor(settings: any) {
        this.userManager = new UserManager(settings)
    }

    public getUser(): Promise<any> {
        return this.userManager.getUser()
    }

    public willTokenExpire(): Promise<any> {
        return this.userManager.getUser().then(user => {
          if (!user) return true;

          const date = new Date()
          const dateExpired = new Date((user.expires_at * 1000) - 2*60000)
          const willExpireSoon = !user ||  dateExpired < date

          return willExpireSoon
        })
    }
  
    public async login(): Promise<any> {
      localStorage.clear()
      return this.userManager.signinRedirect()
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect()
    }

    public logoutUser(): Promise<void> {
      this.userManager.clearStaleState()
      this.userManager.removeUser()
      this.userManager.revokeAccessToken()
      return this.userManager.signoutPopup()
    }
    
    public signinRedirectCallback(): Promise<User> {
        return this.userManager.signinRedirectCallback()
    }

    public getAccessToken(): Promise<string> {
      return this.userManager.getUser().then((data: any) => {
          if (data) return data.access_token
          else return null
      })
    }

    public loginCallback() {
      return this.userManager
        .signinRedirectCallback()
    }

    public silentLoginCallback() {
      return this.userManager
        .signinSilentCallback()
    }

    public tryLogin() {
      return this.userManager
        .signinSilent()
        .catch(err => {
          localStorage.clear()
          console.log(err)
          this.login()
        })
    }
}