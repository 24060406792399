export default [
  {
    path: '/charte-graphique',
    name: 'charte-graphique',
    component: () => import('@/views/charte-graphique/Index.vue'),
    meta: {
      pageTitle: 'Charte graphique',
      breadcrumb: [
        {
          text: 'Charte graphique',
          active: true,
        },
      ],
    }
  }
]