import api from '@/libs/axios'
import { PartiePrenanteAdministratif } from '../models/actionsAdministratif/partiePrenanteAdministratif';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'PartiesPrenantesActionsAdministatif'

export default class PartiesPrenantesAdministratifRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, includeType?: boolean): Promise<PaginatedList<PartiePrenanteAdministratif>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (includeType) params.append('IncludeType', includeType.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
