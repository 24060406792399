import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { Vaccination, VaccinationCreateModel, VaccinationUpdateModel } from '../models/dossiers/vaccination';

const baseResource = 'Dossiers'
const resource = 'Vaccinations'

export default class VaccinationsResource {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number): Promise<PaginatedList<Vaccination>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params })
    return response.data
  }

  public async get(dossierId: string, id: string): Promise<Vaccination> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`);
    return response.data;
  }

  public async post(dossierId: string, data : VaccinationCreateModel) : Promise<Vaccination> {
    let formData = new FormData();

    if (data.date) formData.append('date', data.date)
    if (data.numLot) formData.append('numLot', data.numLot.toString())
    if (data.marque) formData.append('marque', data.marque.toString())
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.dossierId) formData.append('dossierId', data.dossierId)
    if (data.siteInjection) formData.append('siteInjection', data.siteInjection.toString())

    data.maladiesId?.forEach((id: number) => formData.append('maladiesId', id.toString()));
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData)
    return response.data
  }

  public async put(dossierId: string, id: string, data : VaccinationUpdateModel) : Promise<Vaccination> {
    let formData = new FormData();

    if (data.id) formData.append('id', id)
    if (data.date) formData.append('date', data.date)
    if (data.numLot) formData.append('numLot', data.numLot.toString())
    if (data.marque) formData.append('marque', data.marque.toString())
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.dossierId) formData.append('dossierId', data.dossierId)
    if (data.siteInjection) formData.append('siteInjection', data.siteInjection.toString())

    data.maladiesId?.forEach((id: number) => formData.append('maladiesId', id.toString()));
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, formData)
    return response.data
  }

  public async delete(dossierId: string, id : string) : Promise<Vaccination> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(dossierId:number, vaccinationId: number, fileId: number) : Promise<void> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${vaccinationId}/files/${fileId}`)
    return response.data
  }
}
