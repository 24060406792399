import api from '@/libs/axios'
import { PaginatedList } from '../../models/common/paginatedList'
import { TypeTache } from '@/api/models/suiviTaches/tache';

const baseResource = 'Entreprises'
const resource = 'TypesTache'

export default class TypesTacheEntrepriseResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<TypeTache>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search) params.append('Search', search);

    const response = await api.get(`${resource}`, { params: params })
    return response.data
  }
}
