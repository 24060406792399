import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Identity } from '../models/authorization/identities/identity'

const resource = 'Identities'

export default class IdentitiesResource {
  public async get(claimValue: string) : Promise<PaginatedList<Identity>> {
    const response = await api.get(`${resource}/${claimValue}`)
    
    return response.data
  }
}
