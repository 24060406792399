export default [
  {
    path: '/consignes',
    name: 'consignes',
    component: () => import('@/views/consignes/IndexConsignes.vue'),
    meta: {
      pageTitle: 'Mes consignes',
      breadcrumb: [
        {
          text: 'Mes consignes',
          active: true,
        },
      ],
      rights: [
        'CONSIGNES',
      ],
      resource: 'ACL',
      action: 'read',
      
    },
  },
  {
    path: '/consignes/:id',
    name: 'consignes-details',
    component: () => import('@/views/consignes/detailsConsigne/DetailsConsigne.vue'),
    meta: {
      pageTitle: 'Mes consignes',
      resource: 'ACL',
      action: 'read',
      rights: [
        'CONSIGNES',
      ],
    },
  }
]
