import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Secteur } from '../models/options/entreprises/secteur'

const baseResource = 'Entreprises'
const resource = 'Secteurs'

export default class SecteursRessource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number, nomSearch?: string, siteSearch?: string, siteId?: string, regionId?: string, brancheId?: string, secteurId?: string) : Promise<PaginatedList<Secteur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (nomSearch && nomSearch.length) params.append('Nom', nomSearch?.toString());
    if (siteSearch && siteSearch.length) params.append('Site', siteSearch?.toString());
    if (siteId && siteId.length) params.append('SiteId', siteId?.toString());
    if (regionId && regionId.length) params.append('RegionId', regionId?.toString());
    if (brancheId && brancheId.length) params.append('BrancheId', brancheId?.toString());
    if (secteurId && secteurId.length) params.append('SecteurId', secteurId?.toString());

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params } )
    return response.data
  }
  
  public async paginatedListForSite(entrepriseId: string, siteId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Secteur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/${siteId}`, { params: params } )
    return response.data
  }

  public async delete(entrepriseId: string, id : string, regionId: string, siteId: string) : Promise<Secteur> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}?regionId=${regionId}&&siteId=${siteId}`)
    return response.data
  }

  public async post(entrepriseId: string, data : Secteur) : Promise<Secteur> {
    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, data)
    return response.data
  }

  public async put(entrepriseId: string, id : string, data : Secteur) : Promise<Secteur> {
    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, data)
    return response.data
  }
}
