import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Dossier } from '../models/dossiers/dossier';
import { Fichier } from '../models/dossiers/fichier';

const baseResource = 'Dossiers'
const resource = 'Fichiers'


export default class FichierDossierResource {
  public async getFiles(dossierId: string): Promise<Fichier> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async deleteFile(dossierId: string, fileId: string) : Promise<Dossier> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${fileId}`)
    return response.data
  }
  
  public async downloadFile(fileId: string) : Promise<any> {
    const response = await api.get(`${resource}/askDownload?fileId=${fileId}`)
    return response.data
  }
}