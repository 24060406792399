import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Tag } from '../models/options/entreprises/tag'

const baseResource = 'Entreprises'
const resource = 'Tags'

export default class TagsResource {
  public async paginatedList(entrepriseId: number, pagenumber?: number, pagesize?: number, search?: string, tagsId?: string[]) : Promise<PaginatedList<Tag>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    if (tagsId && tagsId.length) params.append('TagsId', tagsId.toString()) 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params } )
    return response.data
  }

  public async delete(entrepriseId: string, id : string) : Promise<Tag> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async post(entrepriseId: string, data : Tag) : Promise<Tag> {
    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, data)
    return response.data
  }

  public async put(entrepriseId: number, id : number, data : Tag) : Promise<Tag> {
    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, data)
    return response.data
  }
}
