import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Fichier } from '../models/dossiers/fichier';
import { Entreprise } from '../models/options/entreprises/entreprise';

const baseResource = 'Entreprises'
const resource = 'Fichiers'


export default class FichierEntrepriseResource {
  public async getFiles(entrepriseId: string): Promise<Fichier> {
    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`)
    return response.data
  }

  public async deleteFile(entrepriseId: string, fileId: string) : Promise<Entreprise> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${fileId}`)
    return response.data
  }
  
  public async downloadFile(fileId: string) : Promise<any> {
    const response = await api.get(`${resource}/askDownload?fileId=${fileId}`)
    return response.data
  }
}