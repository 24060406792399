import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { TypeDePoste } from '../models/options/typesDePoste/typeDePoste';

const baseResource = 'SecteursActivite'
const resource = 'TypesDePoste'

export default class TypesDePosteResource {
  public async paginatedList(secteurActiviteId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<TypeDePoste>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    params.append('SecteurActiviteId', secteurActiviteId); 

    const response = await api.get(`${baseResource}/${secteurActiviteId}/${resource}`, { params: params } )
    return response.data
  }
}
