import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { DemandeRetraite } from '../models/dossiers/demandesRetraite/demandeRetraite'
import { DemandeRqth } from '../models/options/demandesRqth/demandeRqth'

const resource = 'DemandesRetraite'
const baseResource = 'dossier'

export default class DemandeRetraiteRessources {
  public async get(dossierId: string) : Promise<any> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async put(dossierId : string, demande: DemandeRetraite, config: any) : Promise<DemandeRqth> {
    let formData = new FormData();

    
    if (demande.dateCarsat) formData.append('dateCarsat', demande.dateCarsat)
    if (demande.dateArccoAgirc) formData.append('dateArccoAgirc', demande.dateArccoAgirc)
    if (demande.dateAutres) formData.append('dateAutres', demande.dateAutres)
    if (demande.dateDepart) formData.append('dateDepart', demande.dateDepart)

    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, fileId: string) : Promise<any> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}
