import store from '@/store';
import { computed } from '@vue/composition-api'

export const canNavigate = function(to) {
  const droits = computed(() => store.state.user.droits)
  const fonctions = computed(() => store.state.user.fonctions_codes)

  return to.matched.some(route => 
    (
      (!route.meta?.rights || route.meta?.rights?.some(droitRoute => droits.value.indexOf(droitRoute) !== -1)) && 
      (!route.meta?.notFonctions || route.meta?.notFonctions?.some(notFoncRoute => fonctions.value.indexOf(notFoncRoute) === -1)) &&
      (!route.meta?.fonctions ||route.meta?.fonctions?.some(foncRoute => fonctions.value.indexOf(foncRoute) !== -1))
    ));
} 

export const _ = undefined
