import api from '@/libs/axios'
// import { EntrepriseWithLastInterventionModel } from '../models/actionsCollectives/actionCollective';
import { PaginatedList } from '../models/common/paginatedList'
import { Entreprise, EntrepriseShortStat, CreateEntreprise } from '../models/options/entreprises/entreprise';
import { EntrepriseMapping } from '../models/options/myRessif/EntrepriseMapping';
import { Fichier } from '../models/entreprise/fichier';

const resource = 'Entreprises'

export default class EntreprisesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string, withoutNational?: boolean, entrepriseId?: string) : Promise<PaginatedList<Entreprise>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    if (withoutNational) params.append('withoutNational', 'true')
    if (entrepriseId && entrepriseId.length) params.append('EntrepriseId', entrepriseId?.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getLasts() : Promise<PaginatedList<EntrepriseShortStat>> {
    const response = await api.get(`${resource}/lasts`)
    return response.data
  }
  
  // public async getLastsForActionCo() : Promise<PaginatedList<EntrepriseWithLastInterventionModel>> {
  //   const response = await api.get(`${resource}/lasts-actionsco`)
  //   return response.data
  // }

  public async getById(id: string, includeStats: boolean = false) {
    const params = new URLSearchParams();
    if (includeStats) params.append('includeStats', 'true')
    
    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async getEntrepriseMappings(entrepriseId: string) : Promise<any[]> {
    const response = await api.get(`/${resource}/${entrepriseId}/DaisyMappings`)
    return response.data
  }

  public async post(data : CreateEntreprise) : Promise<Entreprise> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Entreprise> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async put(entrepriseId: string, data:Entreprise) : Promise<Entreprise> {
    const response = await api.put(`${resource}/${entrepriseId}`, data)
    return response.data
  }

  public async uploadFichier(
    id: string,
    files: Fichier[],
    config: any
  ): Promise<any> {
    let formData = new FormData();
    files.forEach((file: any) => formData.append("fichiers", file));

    const response = await api.post(
      `${resource}/${id}/Fichiers`,
      formData,
      config
    );
    return response.data;
  }

  public async getEntrepriseTenants(entrepriseId: string) : Promise<any[]> {
    const response = await api.get(`/${resource}/${entrepriseId}/tenants`)
    return response.data
  }

  public async createEntrepriseTenant(id: string, tenantId: string) : Promise<Entreprise> {
    const params = {
      entrepriseId: id,
      tenantId: tenantId
    }
    
    const response = await api.post(`${resource}/${id}/tenants`, params)
    return response.data
  }

  public async deleteEntrepriseTenant(id: string, tenantId: string) : Promise<Entreprise> {
    const response = await api.delete(`${resource}/${id}/tenants/${tenantId}`)

    return response.data
  }

  public async addEntrepriseMapping(id: string, data: EntrepriseMapping) {
    const response = await api.post(`${resource}/${id}/mappings`, data)
    return response.data
  }

  public async deleteEntrepriseMapping(id: string, myRessifEntrepriseId: string) : Promise<Entreprise> {
    const response = await api.delete(`${resource}/${id}/mapping/${myRessifEntrepriseId}`)

    return response.data
  }

  public async putAllCrl(entrepriseId: string, data: any) : Promise<any> {
    const response = await api.put(`${resource}/${entrepriseId}/AllCrl`, data)
    return response.data
  }
}
