import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { EtudeDePoste, CreateEtudeDePosteModel } from '../models/dossiers/etudeDePoste'
import { NiveauAlerteCreateModel, NiveauxAlertCreateModelPost } from '../models/dossiers/niveauxAlerte'
import { RisqueToSave } from '../models/dossiers/risques'
import { ValeurMesureCreateModel } from '../models/dossiers/valeursMesures'

const baseResource = 'Dossiers'
const resource = 'EtudesDePoste'

export default class EtudesDePosteResource {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<EtudeDePoste>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params } )
    return response.data
  }

  public async post(dossierId: string, data : CreateEtudeDePosteModel, config: any) : Promise<EtudeDePoste> {
    let formData = new FormData();
    if (data.dateEtudeDePoste) formData.append('dateEtudeDePoste', data.dateEtudeDePoste)
    if (data.typeDePosteId) formData.append('typeDePosteId', data.typeDePosteId.toString())
    if (data.prescripteurId) formData.append('prescripteurId', data.prescripteurId.toString())
    if (data.note?.message) formData.append('note', data.note.message)
    if (data.note?.id) formData.append('note', data.note.id)
    if (data.dossierId) formData.append('dossierId', data.dossierId)
    if (data.duree) formData.append('duree', data.duree.toString())
    if (data.stress) formData.append('stress', data.stress.toString())
    if (data.secteurActiviteId) formData.append('secteurActiviteId', data.secteurActiviteId.toString())
    
    data.niveauxAlertes?.forEach((niveauxAlerte: NiveauxAlertCreateModelPost, index) => {
      this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`)
    })

    data.niveauxAlertes?.forEach((niveauxAlerte: NiveauxAlertCreateModelPost, index) => {
      this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`)
    })
    data.valeursMesures?.forEach((valeurMesure: ValeurMesureCreateModel, index) => {
      this.appendArray(formData, valeurMesure, `valeursMesures[${index}]`)
    })
    data.risques?.forEach((risque: RisqueToSave, index) => {
      this.appendArray(formData, risque, `risques[${index}]`)
    })
    data.binomesId?.forEach((id: number) => formData.append('binomesId', id.toString()));
    data.contextesId?.forEach((id: number) => formData.append('contextesId', id.toString()));
    data.risquesIds?.forEach((id: number) => formData.append('risquesIds', id.toString()));
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  
  public async put(dossierId: string, id: string,  data: CreateEtudeDePosteModel, config:any): Promise<string> {
    let formData = new FormData();    
    

    if (data.dateEtudeDePoste) formData.append('dateEtudeDePoste', data.dateEtudeDePoste)
    if (data.typeDePosteId) formData.append('typeDePosteId', data.typeDePosteId.toString())
    if (data.prescripteurId) formData.append('prescripteurId', data.prescripteurId.toString())
    if (data.note?.message) formData.append('note', data.note.message)
    if (data.note?.id) formData.append('noteId', data.note.id)
    if (data.dossierId) formData.append('dossierId', data.dossierId)
    if (data.duree) formData.append('duree', data.duree.toString())
    if (data.stress) formData.append('stress', data.stress.toString())
    if (data.secteurActiviteId) formData.append('secteurActiviteId', data.secteurActiviteId.toString())
    
    data.niveauxAlertes?.forEach((niveauxAlerte: NiveauxAlertCreateModelPost, index) => {
      this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`)
    })

    data.niveauxAlertes?.forEach((niveauxAlerte: NiveauxAlertCreateModelPost, index) => {
      this.appendArray(formData, niveauxAlerte, `niveauxAlertes[${index}]`)
    })
    data.valeursMesures?.forEach((valeurMesure: ValeurMesureCreateModel, index) => {
      this.appendArray(formData, valeurMesure, `valeursMesures[${index}]`)
    })
    data.risques?.forEach((risque: RisqueToSave, index) => {
      this.appendArray(formData, risque, `risques[${index}]`)
    })
    data.binomesId?.forEach((id: number) => formData.append('binomesId', id.toString()));
    data.contextesId?.forEach((id: number) => formData.append('contextesId', id.toString()));
    data.risquesIds?.forEach((id: number) => formData.append('risquesIds', id.toString()));
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, formData, config)
    return response.data
  }

  public async deleteFile(dossierId:number, etudeDePosteId: number, fileId: number) : Promise<void> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${etudeDePosteId}/files/${fileId}`)
    return response.data
  }

    
  appendArray(form_data: any, values: any, name: any){
    if (!values && name)
      form_data.append(name, '');
    else
    {
      if (typeof values == 'object'){
        for(let key in values){
          if(typeof values[key] == 'object')
            this.appendArray(form_data, values[key], name + '[' + key + ']');
          else
            if (values[key] != undefined)
              form_data.append(name + '[' + key + ']', values[key]);
        }
      }
      else
        form_data.append(name, values);
    }

    return form_data;
  }
}
