import api from '@/libs/axios'
import { MaladieProfessionnelle } from '../models/dossiers/informationSante'
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'MaladiesProfessionnelles'

export default class MaladiesProfessionnellesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string, isAgricole?: boolean) : Promise<PaginatedList<MaladieProfessionnelle>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    if (isAgricole != undefined) params.append('IsAgricole', isAgricole.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
