import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Site } from '../models/options/entreprises/site'

const baseResource = 'Entreprises'
const resource = 'Sites'

export default class SitesRessource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number, nomSearch?: string, regionSearch?: string, brancheSearch?: string, regionId?: string, brancheId?: string, siteId?: string) : Promise<PaginatedList<Site>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (nomSearch && nomSearch.length) params.append('Nom', nomSearch?.toString());
    if (regionSearch && regionSearch.length) params.append('Region', regionSearch?.toString());
    if (brancheSearch && brancheSearch.length) params.append('Branche', brancheSearch?.toString());
    if (regionId && regionId.length) params.append('RegionId', regionId?.toString()); 
    if (brancheId && brancheId.length) params.append('BrancheId', brancheId?.toString()); 
    if (siteId && siteId.length) params.append('SiteId', siteId?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params } )
    return response.data
  }

  public async paginatedListByRegionId(entrepriseId: string, regionId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Site>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/RegionId/${regionId}`, { params: params } )
    return response.data
  }

  public async paginatedListByBrancheId(entrepriseId: string, brancheId: string, pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Site>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/BrancheId/${brancheId}`, { params: params } )
    return response.data
  }

  public async delete(entrepriseId: string, id : string, regionId: string) : Promise<Site> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}?regionId=${regionId}`)
    return response.data
  }

  public async post(entrepriseId: string, data : Site) : Promise<Site> {
    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, data)
    return response.data
  }

  public async put(entrepriseId: string, id : string, data : Site) : Promise<Site> {
    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, data)
    return response.data
  }
}
