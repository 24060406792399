import api from '@/libs/axios'
import { DossierCreateModel } from '../models/dossiers/dossier'
import { PaginatedList } from '../models/common/paginatedList'
import { Dossier } from '../models/dossiers/dossier'
import { SituationProfessionnelles } from '../models/options/situationProfessionnelles/situationProfessionnelle'
import { InformationsContratCreateModel } from '../models/dossiers/situationProfessionnelle'

const baseResource = 'dossiers'
const resource = 'SituationProfessionnelles'

export default class SituationProfessionnellesRessource {
  public async get(dossierId: string ) : Promise<SituationProfessionnelles> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async getStatut(dossierId: string, contratId: string) : Promise<any> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${contratId}/statut`)
    return response.data
  }

  public async put(dossierId: string, data: any) : Promise<void> { 
    const response = await api.put(`${baseResource}/${dossierId}/${resource}`, data)
    return response.data
  }
}
