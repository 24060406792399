import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { DemandeAideFinanciere } from '../models/dossiers/demandesAideFinanciere/demandeAideFinanciere'

const baseResource = 'dossier'
const resource = 'DemandesAideFinanciere'


export default class DemandeAideFinanciereResource {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<DemandeAideFinanciere>> {
    const params = new URLSearchParams();

    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search && search.length) params.append('Search', search?.toString());

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params })
    return response.data
  }

  public async post(dossierId: string, demande: DemandeAideFinanciere, config: any): Promise<DemandeAideFinanciere> {
    let formData = new FormData();

    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateResponse) formData.append('dateResponse', demande.dateResponse)
    if (demande.typeAideId) formData.append('typeAideId', demande.typeAideId)
    if (demande.montant != null) formData.append('montant', demande.montant.toString())

    if (demande.accord != null && demande.accord != undefined)
      formData.append('accord', demande.accord ? demande.accord.toString() : false.toString())

    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(dossierId: string, id: string): Promise<DemandeAideFinanciere> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async put(dossierId: string, id: string, demande: DemandeAideFinanciere, config: any): Promise<DemandeAideFinanciere> {
    let formData = new FormData();

    formData.append('id', id)
    if (demande.dateDemande) formData.append('dateDemande', demande.dateDemande)
    if (demande.dateResponse) formData.append('dateResponse', demande.dateResponse)
    if (demande.typeAideId) formData.append('typeAideId', demande.typeAideId)
    if (demande.montant != null) formData.append('montant', demande.montant.toString())

    if (demande.accord != null && demande.accord != undefined)
      formData.append('accord', demande.accord ? demande.accord.toString() : false.toString())
      
    if (demande.commentaire) formData.append('commentaire', demande.commentaire)
    demande.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, formData)
    return response.data
  }

  public async getById(dossierId: string, id: string): Promise<DemandeAideFinanciere> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(dossierId: string, id: string, fileId: string): Promise<DemandeAideFinanciere> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}