import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Branche } from '../models/options/entreprises/branche'

const baseResource = 'Entreprises'
const resource = 'Branches'

export default class BranchesResource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number, search?: string, brancheId?: string) : Promise<PaginatedList<Branche>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 
    if (brancheId && brancheId.length) params.append('BrancheId', brancheId?.toString()); 

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params } )
    return response.data
  }

  public async delete(entrepriseId: string, id : string) : Promise<Branche> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async post(entrepriseId: string, data : Branche) : Promise<Branche> {
    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, data)
    return response.data
  }

  public async put(entrepriseId: string, id : string, data : Branche) : Promise<Branche> {
    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, data)
    return response.data
  }
}
