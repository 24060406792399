import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { TypeContrat } from '../models/options/typeContrats/typeContrat'

const resource = 'TypeContrats'

export default class TypeContratsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifonly?: boolean) : Promise<PaginatedList<TypeContrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (actifonly) params.append('ActifOnly', actifonly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async post(data : TypeContrat) : Promise<TypeContrat> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<TypeContrat> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async put(id : string, data : TypeContrat) : Promise<TypeContrat> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }


  public async uploadFile(file: any, config: any): Promise<any> {
        
    let formData = new FormData();
    formData.append('files', file);
    formData.append('strings', "file");


    // config = {
    //   onUploadProgress: function(progressEvent: any) {
    //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //   }
    // }

    const response = await api.post(`entreprises/13/ActionsCollectives/12`, formData, {
      onUploadProgress: function(progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    })
    return response.data
  }
}
