import appApiTypes from '@/api'

function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem('me') && IsJsonString(localStorage.getItem('me')) ? JSON.parse(localStorage.getItem('me')) : null,
    droits: localStorage.getItem('user_ui_rights') && IsJsonString(localStorage.getItem('user_ui_rights')) ? JSON.parse(localStorage.getItem('user_ui_rights')) : [],
    fonctions: localStorage.getItem('user_ui_fonctions') && IsJsonString(localStorage.getItem('user_ui_fonctions')) ? JSON.parse(localStorage.getItem('user_ui_fonctions')) : [],
    fonctions_codes: localStorage.getItem('user_ui_fonctions_codes') && IsJsonString(localStorage.getItem('user_ui_fonctions_codes')) ? JSON.parse(localStorage.getItem('user_ui_fonctions_codes')) : [],    
    user_preferences: localStorage.getItem('user_ui_preferences') && IsJsonString(localStorage.getItem('user_ui_preferences')) ? JSON.parse(localStorage.getItem('user_ui_preferences')) : [],
    tenant_preferences: localStorage.getItem('tenant_ui_preferences') && IsJsonString(localStorage.getItem('tenant_ui_preferences')) ? JSON.parse(localStorage.getItem('tenant_ui_preferences')) : []
  },
  getters: {
    me: function(state) {
      return { ...state.user, fonctions: state.fonctions, fonctionsCodes: state.fonctions_codes, userPreferences: state.user_preferences, tenantPreferences : state.tenant_preferences }
    },
    droits: state => state.droits,
    fonctions: state => state.fonctions,
    fonctions_codes: state => state.fonctions_codes,
    user_preferences: state => state.user_preferences,
    tenant_preferences: state => state.tenant_preferences
  },
  mutations: {
    SET_ME(state, user) {
      localStorage.setItem('me', JSON.stringify(user))
      state.user = user
    },
    SET_DROITS(state, droits) {
      localStorage.setItem('user_ui_rights', JSON.stringify(droits))
      state.droits = droits
    },
    SET_FONCTIONS(state, fonctions) {
      localStorage.setItem('user_ui_fonctions', JSON.stringify(fonctions))
      state.fonctions = fonctions
    },
    SET_FONCTIONS_CODES(state, fonctions_codes) {
      localStorage.setItem('user_ui_fonctions_codes', JSON.stringify(fonctions_codes))
      state.fonctions_codes = fonctions_codes
    },
    SET_PREFERENCES_UTILISATEUR(state, preferences_utilisateur) {
      localStorage.setItem('user_ui_preferences', JSON.stringify(preferences_utilisateur))
      state.user_preferences = preferences_utilisateur
    },
    SET_TENANT_PREFERENCES(state, tenant_preferences) {
      localStorage.setItem('tenant_ui_preferences', JSON.stringify(tenant_preferences))
      state.tenant_preferences = tenant_preferences
    }
  },
  actions: {
    setMe({ commit }, user) {
      commit('SET_ME', user)
    },
    fetchDroits({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.myprofile.get()
          .then(response => {
            commit('SET_DROITS', response.droits)
            commit('SET_FONCTIONS', response.fonctions)
            commit('SET_FONCTIONS_CODES', response.fonctionsCode)
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    saveUserPreference({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.preferencesUtilisateur.post(payload)
          .then(response => {
            dispatch('fetchPreferencesUtilisateur');
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    updateUserPreference({ commit, dispatch },payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.preferencesUtilisateur.put(payload.id ,payload)
          .then(response => {
            dispatch('fetchPreferencesUtilisateur')
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    fetchPreferencesUtilisateur({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.preferencesUtilisateur.get()
          .then(response => {
            commit('SET_PREFERENCES_UTILISATEUR', response.items)  
            resolve(response);        
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    fetchTenantPreferences({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.tenantPreferences.get()
          .then(response => {
            if(response.items) {
              response.items.forEach(item => {
                if(item.value === "true" || item.value === "false") {
                  item.value = JSON.parse(item.value);
                }
              }) 
            }
            commit('SET_TENANT_PREFERENCES', response.items);
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    updateTenantPreference({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.ressifnet.tenantPreferences.put(payload)
          .then(response => {
            dispatch('fetchTenantPreferences');
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
  }
}