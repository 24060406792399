import api from '@/libs/axios'

const resource = 'Utils'

export default class UtilsRessource {
  public async downloadAsset(fileCode: string) : Promise<any> {
    const response = await api.get(`${resource}/downloads/assets/${fileCode}`)
    return response.data
  }

  public async Logger(message: string, type: string): Promise<any> {
    var formData = new FormData()

    formData.append('message', message)
    formData.append('type', type)

    const response = await api.post(`${resource}/logger`, formData)
    
    return response.data;
  }
}
