import api from '@/libs/axios'
import { TypeActionMilieuTravail } from '../models/actionsMilieuTravail/typeActionMilieuTravail';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'TypesActionMilieuTravail'

export default class TypesActionAdministratifRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifOnly?: boolean): Promise<PaginatedList<TypeActionMilieuTravail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifOnly) params.append('ActifOnly', actifOnly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
