import api from '@/libs/axios'
import { Destinataire } from '../models/dossiers/destinataire';
import { ConsigneListeFiltres } from '../models/dossiers/consignes';

const resource = 'Destinataires'

export default class DestinatairesRessource {
  public async get(id: string, filtres: ConsigneListeFiltres) : Promise<Destinataire> { 
    const params = new URLSearchParams();
    if (filtres.currentPage) params.append('PageNumber', filtres.currentPage.toString()); 
    if (filtres.perPage) params.append('PageSize', filtres.perPage.toString()); 
    if(filtres.onlyTraitee != null) params.append('IsTraitee', filtres.onlyTraitee.toString());
    if(filtres.onlyReceived != null) params.append('OnlyReceived', filtres.onlyReceived.toString());
    if(filtres.onlyCreatedByMe != null) params.append('OnlyCreatedByMe', filtres.onlyCreatedByMe.toString()); 
    if(filtres.onlyDossiers != null) params.append("OnlyDossiers", filtres.onlyDossiers.toString());
    if(filtres.onlyEntreprises != null) params.append("OnlyEntreprises", filtres.onlyEntreprises.toString());
    if(filtres.order != null) params.append('Order', filtres.order.toString()); 
    if(filtres.typeConsigne != null) params.append('TypeConsigne', filtres.typeConsigne.toString()); 

    const response = await api.get(`${resource}/${id}`, { params: params } )
    return response.data
  }
}