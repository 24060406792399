export default [
    {
      path: '/documents-telecharger',
      name: 'documents-telecharger',
      component: () => import('@/views/documents-telecharger/Document.vue'),
      meta: {
        pageTitle: 'Document à télécharger',
        breadcrumb: [
          {
            text: 'Document à télécharger',
            active: true,
          },
        ],
      },
    },

  ]