export default [
  // {
  //   path: '/statistiques-generales',
  //   name: 'search-statistiques-generales',
  //   component: () => import('@/views/statistiques/generales/Search.vue'),
  //   meta: {
  //     pageTitle: 'Statistiques générales',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques générales',
  //         active: true,
  //       },
  //     ],
  //     // rights: [
  //     //   'STATISTIQUES'
  //     // ],
  //     // notFonctions: [
  //     //   'CRL', 'RHU'
  //     // ]
  //   },
  // },
  // {
  //   path: '/statistiques-crl',
  //   name: 'search-statistiques-crl',
  //   component: () => import('@/views/statistiques/crl/Search.vue'),
  //   meta: {
  //     pageTitle: 'Statistiques CRL',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques CRL',
  //         active: true,
  //       },
  //     ],
  //     // rights: [
  //     //   'STATISTIQUES'
  //     // ],
  //     // fonctions: [
  //     //   'CRL', 'RHU', 'ADMNAT'
  //     // ]
  //   },
  // },
  // {
  //   path: '/statistiques-dossiers-instruits',
  //   name: 'search-statistiques-dossiers-instruits',
  //   component: () => import('@/views/statistiques/dossiers-instruits/Search.vue'),
  //   meta: {
  //     pageTitle: 'Statistiques des dossiers instruits',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques des dossiers instruits',
  //         active: true,
  //       },
  //     ],
  //     // rights: [
  //     //   'STATISTIQUES'
  //     // ],
  //     // notFonctions: [
  //     //   'CRL', 'RHU'
  //     // ]
  //   },
  // },
  // {
  //   path: '/statistiques-facturation',
  //   name: 'search-statistiques-facturation',
  //   component: () => import('@/views/statistiques/facturation/Search.vue'),
  //   meta: {
  //     pageTitle: 'Statistiques Facturation',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques Facturation',
  //         active: true,
  //       },
  //     ],
  //     // rights: [
  //     //   'STATISTIQUES' 
  //     // ],
  //   },
  // },
]