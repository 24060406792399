import TypeContratsResource from "./ressources/typeContrats"
import DomainesResource from "./ressources/domaines"
import SituationsFamilialeResource from "./ressources/situationsFamiliale"
import EntreprisesResource from "./ressources/entreprises"
import RegionsResource from "./ressources/regions"
import CategoriesSocioProResource from "./ressources/categoriesSocioPro"
import BranchesRessource from './ressources/branches'
import SitesRessource from "./ressources/sites"
import TagsRessource from "./ressources/tags"
import MyProfileResource from "./ressources/myprofile"
import UserDetailsResource from "./ressources/userDetails"
import ProfilsResource from "./ressources/profils"
import FonctionsResource from "./ressources/fonctions"
import IdentitiesResource from "./ressources/identities"
import DroitsResource from "./ressources/droits"
import SecteursRessource from "./ressources/secteurs"
import HorairesRessource from "./ressources/horaires"
import ActionsAdministratifResource from "./ressources/actionsAdministratif"
import ActionsMilieuTravailResource from "./ressources/actionsMilieuTravail"
import ActionsPreventionSanteTravailResource from "./ressources/actionsPreventionSanteTravail"
import ActionsPreventionSantePubliqueResource from "./ressources/actionsPreventionSantePublique"
import DossiersRessource from "./ressources/dossiers"
import TauxIPPsResource from "./ressources/tauxIPPs"
import CategoriesInvaliditesResource from "./ressources/categoriesInvalidites"
import TempsTravailResource from "./ressources/tempsTravails"
import SituationProfessionnellesRessource from "./ressources/situationProfessionnelle"
import TypesActionAdministratifRessource from "./ressources/typesActionAdministratif"
import TypesActionMilieuTravailResource from "./ressources/typesActionMilieuTravail"
import PartiesPrenantesAdministratifRessource from "./ressources/partiesPrenantesAdministratif"
import PartiesPrenantesMilieuTravailRessource from "./ressources/partiesPrenantesMilieuTravail"
import ThemesRessources from "./ressources/themes"
import DemandeRqthResource from "./ressources/demandesRqth"
import DemandeSurendettementResource from "./ressources/demandesSurendettement"
import SecteursActivitesRessource from "./ressources/secteursActivites"
import EquipesResource from "./ressources/equipes"
import NotesResource from "./ressources/notes"
import TypesInterventionRessources from "./ressources/typesInterventions"
import TypesInterlocuteurRessources from "./ressources/typesInterlocuteurs"
import ModeContactsRessources from "./ressources/modeContacts"
import UtilsRessource from "./ressources/utils"
import SituationTravailRessources from "./ressources/situationsTravails"
import TypeMiseADispositionRessources from "./ressources/typesMiseADisposition"
import ActionsResources from "./ressources/actions"
import StatistiquesResource from "./ressources/statistiques"
import ConsignesResources from "./ressources/consignes"
import DemandeRetraiteRessources from "./ressources/demandesRetraite"
import DestinatairesRessource from "./ressources/destinataires"
import DemandeLogementRessources from "./ressources/demandesLogement"
import OrganismesDemandeLogementRessources from "./ressources/organismesDemandeLogement"
import DemandeCapitalDecesRessources from "./ressources/demandesCapitalDeces"
import DemandeInvaliditeRessources from "./ressources/demandesInvalidite"
import DemandeAideFinanciereResource from "./ressources/demandesAideFinanciere"
import TypesAidesFinanciereResource from "./ressources/typesAidesFinanciere"
import FichierDossierResource from "./ressources/fichiersDossiers"
import CategoriesOrientationsResource from "./ressources/categoriesOrientations"
import DomainesNouveauSansDossierResource from "./ressources/domainesNouveauSansDossier"
import NouveauSansDossiersResource from "./ressources/nouveauSansDossiers"
import TenantsResource from "./ressources/tenants"
import MyRessifResource from "./ressources/myRessif"
import RapportActivitesRessource from "./ressources/rapportActivites"
import RapportActiviteTemplatesRessource from "./ressources/rapportActiviteTemplates"
import PreferencesUtilisateurResource from "./ressources/preferencesUtilisateur"
import CharteGraphiqueRessource from "./ressources/charteGraphique"
import RisquesRessource from "./ressources/risques"
import MesuresRessource from "./ressources/mesures"
import FamillesDeRisqueRessource from "./ressources/famillesDeRisque"
import ImpactsRessource from "./ressources/impacts"
import SousImpactRessource from "./ressources/sousImpacts"
import ContextesResource from './ressources/contextes';
import BinomesResource from './ressources/binomes';
import TypesDePosteResource from './ressources/typesDePoste';
import PrescripteursResource from './ressources/prescripteurs';
import EtudesDePosteResource from './ressources/etudesDePoste';
import ProjetsPreventionSanteTravailResource from "./ressources/projetPreventionSanteTravail"
import TypesActionPreventionSanteTravailRessource from "./ressources/typesActionPreventionSanteTravail"
import ThemesPreventionSanteTravailRessources from "./ressources/themesPreventionSanteTravail"
import BinomesEntrepriseResource from "./ressources/binomesEntreprise"
import ContextesEntrepriseResource from "./ressources/contextesEntreprise"
import FamillesDeRisqueEntrepriseRessource from "./ressources/famillesDeRisqueEntreprise"
import ImpactsEntrepriseRessource from "./ressources/impactsEntreprise"
import PrescripteursEntrepriseResource from "./ressources/prescripteursEntreprise"
import TypesDePosteEntrepriseResource from "./ressources/typesDePosteEntreprise"
import InformationsSanteResource from "./ressources/informationsSante"
import VaccinationsResource from "./ressources/vaccinations"
import MaladiesRessource from "./ressources/maladies"
import ProjetsPreventionSantePubliqueResource from "./ressources/projetPreventionSantePublique"
import TypesActionPreventionSantePubliqueRessource from "./ressources/typesActionPreventionSantePublique"
import ThemesPreventionSantePubliqueRessources from "./ressources/themesPreventionSantePublique"
import FichierEntrepriseResource from "./ressources/fichiersEntreprises"
import MoyensLocomotionRessource from "./ressources/moyensLocomotion"
import HabilitationsResource from "./ressources/habilitation"
import TypeHabilitationsResource from "./ressources/typeHabilitations"
import AptitudesAvisResource from "./ressources/aptitudesAvis"
import NaturesRestrictionsResource from "./ressources/naturesRestrictions"
import AmenagementsResource from "./ressources/amenagements";
import PassagesInfirmerieResource from "./ressources/passagesInfirmerie"
import OriginesRessource from "./ressources/origines"
import PISituationProfessionnelleRessource from "./ressources/piSituationProfessionnelles"
import ActionsRealiseesRessource from "./ressources/actionsRealisees"
import TypesVisiteRessource from "./ressources/typesVisite"
import { TypeSuivi } from "./models/options/typesSuivi/typeSuivi"
import TypesSuiviRessource from "./ressources/typesSuivi"
import { PrecisionNatureTypeVisite } from "./models/options/precisionsNatureTypeVisite/precisionNatureTypeVisite"
import PrecisionsNatureTypeVisiteRessource from "./ressources/precisionsNatureTypeVisite"
import DetailsTypeVisiteRessource from "./ressources/detailsTypeVisite"
import RaisonsVenueRessource from "./ressources/raisonsVenue"
import { PrecisionRaisonVenue } from "./models/options/precisionsRaisonVenue/precisionRaisonVenue"
import PrecisionsRaisonVenueRessource from "./ressources/precisionsRaisonVenue"
import DouleursPsychiquesRessource from "./ressources/douleursPsychiques"
import DouleursPhysiquesRessource from "./ressources/douleursPhysiques"
import OrientationsRessource from "./ressources/orientations"
import PointsPositifsRessource from "./ressources/pointsPositifs"
import PointsNegatifsRessource from "./ressources/pointsNegatifs"
import EquipementsResource from './ressources/equipements';
import TachesEntrepriseResource from "./ressources/suiviTaches/taches"
import TypesTacheEntrepriseResource from "./ressources/suiviTaches/typesTache"
import MaladiesProfessionnellesResource from "./ressources/maladiesProfessionnelles"
import TenantPreferencesResource from "./ressources/tenantPreferences"
import ChangelogsResources from "./ressources/changelogs"
import UnreadChangelogsResource from "./ressources/unreadChangelogs"

// eslint-disable-next-line no-use-before-define
const ressifnetApi: RessifnetApiType = {
  typeContrats: new TypeContratsResource(),
  domaines: new DomainesResource(),
  situationsFamiliale: new SituationsFamilialeResource(),
  entreprises: new EntreprisesResource(),
  regions: new RegionsResource(),
  categoriesSocioPro: new CategoriesSocioProResource(),
  branches: new BranchesRessource(),
  sites: new SitesRessource(),
  tags: new TagsRessource(),
  myprofile: new MyProfileResource(),
  userDetails: new UserDetailsResource(),
  profils : new ProfilsResource(),
  fonctions: new FonctionsResource(),
  identities: new IdentitiesResource(),
  secteurs: new SecteursRessource(),
  horaires: new HorairesRessource(),
  droits: new DroitsResource(),
  dossiers: new DossiersRessource(),
  tauxIPPs: new TauxIPPsResource(),
  categoriesInvalidites: new CategoriesInvaliditesResource(),
  tempsTravail: new TempsTravailResource(),
  actionsAdministratif: new ActionsAdministratifResource(),
  actionsMilieuTravail: new ActionsMilieuTravailResource(),
  actionsPreventionSanteTravail: new ActionsPreventionSanteTravailResource(),
  actionsPreventionSantePublique: new ActionsPreventionSantePubliqueResource(),
  typesActionAdministratif: new TypesActionAdministratifRessource(),
  typesActionMilieuTravail: new TypesActionMilieuTravailResource(),
  partiesPrenantesAdministratif: new PartiesPrenantesAdministratifRessource(),
  partiesPrenantesMilieuTravail: new PartiesPrenantesMilieuTravailRessource(),
  situationProfessionnelles: new SituationProfessionnellesRessource(),
  themes: new ThemesRessources(),
  demandesRqth : new DemandeRqthResource(),
  demandesSurendettement: new DemandeSurendettementResource(),
  secteursActivites: new SecteursActivitesRessource(),
  equipes: new EquipesResource(),
  notes: new NotesResource(),
  typeInterventions: new TypesInterventionRessources(),
  typesActionPreventionSanteTravail: new TypesActionPreventionSanteTravailRessource(),
  typesActionPreventionSantePublique: new TypesActionPreventionSantePubliqueRessource(),
  typeInterlocuteurs: new TypesInterlocuteurRessources(),
  modeContacts: new ModeContactsRessources(),
  utils: new UtilsRessource(),
  situationTravails: new SituationTravailRessources(),
  typesMiseADisposition: new TypeMiseADispositionRessources(),
  actions: new ActionsResources(),
  statistiques: new StatistiquesResource(),
  rapportActivites: new RapportActivitesRessource(),
  rapportActiviteTemplates: new RapportActiviteTemplatesRessource(),
  consignes: new ConsignesResources(),
  demandesRetraite: new DemandeRetraiteRessources(),
  destinataires: new DestinatairesRessource(),
  demandesLogement: new DemandeLogementRessources(),
  organismesDemandeLogement: new OrganismesDemandeLogementRessources(),
  demandesInvalidite: new DemandeInvaliditeRessources(),
  demandesAideFinanciere: new DemandeAideFinanciereResource(),
  typesAidesFinanciere: new TypesAidesFinanciereResource(),
  demandesCapitalDeces: new DemandeCapitalDecesRessources(),
  fichiersDossiers: new FichierDossierResource(),
  fichiersEntreprises: new FichierEntrepriseResource(),
  categoriesOrientations: new CategoriesOrientationsResource(),
  domainesNouveauSansDossier: new DomainesNouveauSansDossierResource(),
  nouveauSansDossiers: new NouveauSansDossiersResource(),
  tenants: new TenantsResource(),
  myRessif: new MyRessifResource(),
  preferencesUtilisateur : new PreferencesUtilisateurResource(),
  charteGraphique: new CharteGraphiqueRessource(),
  risques : new RisquesRessource(),
  mesures : new MesuresRessource(),
  famillesDeRisque : new FamillesDeRisqueRessource(),
  famillesDeRisqueEntreprise : new FamillesDeRisqueEntrepriseRessource(),
  impacts : new ImpactsRessource(),
  impactsEntreprise : new ImpactsEntrepriseRessource(),
  sousImpacts : new SousImpactRessource(),
  contextes: new ContextesResource(),
  contextesEntreprise: new ContextesEntrepriseResource(),
  binomes: new BinomesResource(),
  binomesEntreprise: new BinomesEntrepriseResource(),
  prescripteurs: new PrescripteursResource(),
  prescripteursEntreprise: new PrescripteursEntrepriseResource(),
  typesDePoste: new TypesDePosteResource(),
  typesDePosteEntreprise: new TypesDePosteEntrepriseResource(),
  etudesDePoste: new EtudesDePosteResource(),
  projetsPreventionSanteTravail: new ProjetsPreventionSanteTravailResource(),
  themesPreventionSanteTravail: new ThemesPreventionSanteTravailRessources(),
  projetsPreventionSantePublique: new ProjetsPreventionSantePubliqueResource(),
  themesPreventionSantePublique: new ThemesPreventionSantePubliqueRessources(),
  informationsSante: new InformationsSanteResource(),
  vaccinations: new VaccinationsResource(),
  maladies: new MaladiesRessource(),
  moyensLocomotion: new MoyensLocomotionRessource(),
  habilitations: new HabilitationsResource(),
  typeHabilitations: new TypeHabilitationsResource(),
  aptitudesAvis: new AptitudesAvisResource(),
  amenagements: new AmenagementsResource(),
  naturesRestrictions: new NaturesRestrictionsResource(),
  equipements: new EquipementsResource(),
  passagesInfirmerie: new PassagesInfirmerieResource(),
  origines: new OriginesRessource(),
  piSituationProfessionnelles: new PISituationProfessionnelleRessource(),
  actionsRealisees: new ActionsRealiseesRessource(),
  typesVisite:new TypesVisiteRessource(),
  typesSuivi: new TypesSuiviRessource(),
  detailsTypeVisite: new DetailsTypeVisiteRessource(),
  precisionsNatureTypeVisite: new PrecisionsNatureTypeVisiteRessource(),
  raisonsVenue : new RaisonsVenueRessource(),
  precisionsRaisonVenue : new PrecisionsRaisonVenueRessource(),
  douleursPsychiques : new DouleursPsychiquesRessource(),
  douleursPhysiques: new DouleursPhysiquesRessource(),
  orientations: new OrientationsRessource(),
  pointsNegatifs: new PointsNegatifsRessource(),
  pointsPositifs: new PointsPositifsRessource(),
  taches: new TachesEntrepriseResource(),
  typesTache: new TypesTacheEntrepriseResource(),
  maladiesProfessionnelles: new MaladiesProfessionnellesResource(),
  tenantPreferences: new TenantPreferencesResource(),
}

export interface RessifnetApiType {
  typeContrats: TypeContratsResource,
  domaines: DomainesResource,
  situationsFamiliale: SituationsFamilialeResource,
  entreprises: EntreprisesResource,
  regions: RegionsResource,
  categoriesSocioPro: CategoriesSocioProResource,
  branches: BranchesRessource,
  sites: SitesRessource,
  tags: TagsRessource,
  myprofile: MyProfileResource,
  userDetails: UserDetailsResource,
  profils : ProfilsResource,
  fonctions: FonctionsResource,
  identities: IdentitiesResource,
  secteurs: SecteursRessource,
  horaires: HorairesRessource,
  droits: DroitsResource,
  dossiers: DossiersRessource,
  tauxIPPs: TauxIPPsResource,
  categoriesInvalidites: CategoriesInvaliditesResource,
  tempsTravail: TempsTravailResource,
  actionsAdministratif: ActionsAdministratifResource,
  actionsMilieuTravail: ActionsMilieuTravailResource,
  actionsPreventionSanteTravail: ActionsPreventionSanteTravailResource,
  actionsPreventionSantePublique: ActionsPreventionSantePubliqueResource,
  typesActionAdministratif: TypesActionAdministratifRessource,
  typesActionMilieuTravail: TypesActionMilieuTravailResource,
  partiesPrenantesAdministratif: PartiesPrenantesAdministratifRessource,
  partiesPrenantesMilieuTravail: PartiesPrenantesMilieuTravailRessource,
  situationProfessionnelles: SituationProfessionnellesRessource,
  projetsPreventionSanteTravail: ProjetsPreventionSanteTravailResource,
  projetsPreventionSantePublique: ProjetsPreventionSantePubliqueResource,
  themes: ThemesRessources,
  demandesRqth : DemandeRqthResource,
  demandesSurendettement : DemandeSurendettementResource,
  secteursActivites: SecteursActivitesRessource,
  equipes: EquipesResource,
  notes: NotesResource,
  typeInterventions: TypesInterventionRessources,
  typesActionPreventionSanteTravail: TypesActionPreventionSanteTravailRessource,
  typesActionPreventionSantePublique: TypesActionPreventionSantePubliqueRessource,
  typeInterlocuteurs: TypesInterlocuteurRessources,
  modeContacts: ModeContactsRessources,
  utils: UtilsRessource,
  situationTravails: SituationTravailRessources,
  typesMiseADisposition: TypeMiseADispositionRessources,
  actions: ActionsResources,
  statistiques: StatistiquesResource,
  rapportActivites: RapportActivitesRessource,
  rapportActiviteTemplates: RapportActiviteTemplatesRessource,
  consignes: ConsignesResources,
  demandesRetraite: DemandeRetraiteRessources,
  destinataires: DestinatairesRessource,
  demandesLogement: DemandeLogementRessources,
  organismesDemandeLogement: OrganismesDemandeLogementRessources,
  demandesInvalidite: DemandeInvaliditeRessources,
  demandesAideFinanciere: DemandeAideFinanciereResource,
  typesAidesFinanciere: TypesAidesFinanciereResource,
  demandesCapitalDeces : DemandeCapitalDecesRessources,
  fichiersDossiers : FichierDossierResource,
  fichiersEntreprises: FichierEntrepriseResource,
  categoriesOrientations: CategoriesOrientationsResource,
  domainesNouveauSansDossier: DomainesNouveauSansDossierResource,
  nouveauSansDossiers: NouveauSansDossiersResource,
  tenants: TenantsResource,
  myRessif: MyRessifResource,
  preferencesUtilisateur : PreferencesUtilisateurResource,
  charteGraphique: CharteGraphiqueRessource,
  risques : RisquesRessource,
  mesures : MesuresRessource,
  famillesDeRisque : FamillesDeRisqueRessource,
  famillesDeRisqueEntreprise : FamillesDeRisqueEntrepriseRessource,
  impacts : ImpactsRessource,
  impactsEntreprise : ImpactsEntrepriseRessource,
  sousImpacts : SousImpactRessource,
  contextes: ContextesResource,
  contextesEntreprise: ContextesEntrepriseResource,
  binomes: BinomesResource,
  binomesEntreprise: BinomesEntrepriseResource,
  typesDePoste: TypesDePosteResource,
  typesDePosteEntreprise: TypesDePosteEntrepriseResource,
  prescripteurs: PrescripteursResource,
  prescripteursEntreprise: PrescripteursEntrepriseResource,
  etudesDePoste: EtudesDePosteResource,
  themesPreventionSanteTravail: ThemesPreventionSanteTravailRessources,
  themesPreventionSantePublique: ThemesPreventionSantePubliqueRessources,
  informationsSante: InformationsSanteResource,
  vaccinations: VaccinationsResource,
  maladies: MaladiesRessource,
  moyensLocomotion: MoyensLocomotionRessource,
  habilitations: HabilitationsResource,
  typeHabilitations: TypeHabilitationsResource,
  aptitudesAvis: AptitudesAvisResource,
  amenagements: AmenagementsResource,
  naturesRestrictions: NaturesRestrictionsResource,
  equipements: EquipementsResource,
  passagesInfirmerie: PassagesInfirmerieResource,
  origines: OriginesRessource,
  actionsRealisees: ActionsRealiseesRessource,
  piSituationProfessionnelles: PISituationProfessionnelleRessource,
  typesVisite: TypesVisiteRessource,
  typesSuivi : TypesSuiviRessource,
  precisionsNatureTypeVisite : PrecisionsNatureTypeVisiteRessource,
  detailsTypeVisite : DetailsTypeVisiteRessource,
  raisonsVenue: RaisonsVenueRessource,
  precisionsRaisonVenue: PrecisionsRaisonVenueRessource,
  douleursPsychiques : DouleursPsychiquesRessource,
  douleursPhysiques: DouleursPhysiquesRessource,
  orientations: OrientationsRessource,
  pointsPositifs: PointsPositifsRessource,
  pointsNegatifs: PointsNegatifsRessource
  taches: TachesEntrepriseResource,
  typesTache: TypesTacheEntrepriseResource,
  maladiesProfessionnelles: MaladiesProfessionnellesResource,
  tenantPreferences: TenantPreferencesResource
}

const myRessifApi: MyRessifApiType = {
  changelogs: new ChangelogsResources(),
  unreadChangelogs: new UnreadChangelogsResource()
}

export interface MyRessifApiType {
  changelogs: ChangelogsResources,
  unreadChangelogs: UnreadChangelogsResource
}


export interface AppApiTypes {
  ressifnet: RessifnetApiType,
  myressif: MyRessifApiType
}
const appApiTypes: AppApiTypes = {
  ressifnet: ressifnetApi,
  myressif: myRessifApi
}



export default appApiTypes
