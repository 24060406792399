import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Orientation } from '../models/options/orientations/orientation';

const resource = 'Orientations'

export default class OrientationsRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Orientation>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
